import * as React from "react";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';

import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import SensorsIcon from "@mui/icons-material/Sensors";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import StorageIcon from "@mui/icons-material/Storage";
import StormIcon from "@mui/icons-material/Storm";
import TableChartIcon from "@mui/icons-material/TableChart";
import {
  Button, Divider, Grid, TextField, Typography, ListItemButton
} from "@mui/material";
import Box from "@mui/material/Box";

import { getHistorians, getSensors, getSensorData } from "../../../api";
import CustomCheckBox from "./components/custom-check-box";
import DatePickerComponent from "./components/date-picker";
import FilterBox from "./components/filter-box";
import HistorianCard from "./components/historian-card";
import TSGraph from "./components/line-chart";
import SensorsTable from "./components/sensors-table";
import TSDataTable from "./components/ts-data-table";
import { useGroupColorsConfig, useSelectedSensorConfig, useFilterList } from "./helpers";


export default function SensorHubHome({}) {
  const [historianList, setHistorianList] = React.useState([]);
  const [sensorList, setSensorList] = React.useState([]);
  const [groupsList, setGroupsList] = React.useState([]);
  const [groupColorsMap, setGroupColorsMap] = React.useState({});

  const [selectedHistorian, setSelectedHistorian] = React.useState(null);
  const [selectedSensor, setSelectedSensor] = React.useState({
    name: "",
    description: "",
    sensor_id: "",
  });
  const [sensorIcon, setSensorIcon] = React.useState(<SensorsIcon />);
  const [sensorColor, setSensorColor] = React.useState("white");

  const [dataTab, setDataTab] = React.useState("Chart");
  const [tab, setTab] = React.useState("Models");

  
  const [loading, setLoading] = React.useState(true);
  const [header, setHeader] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [reading, setReading] = React.useState("");
  const [value, setValue] = React.useState("");
  const [startDate, setStartDate] = React.useState(dayjs('2024-06-02T00:00:00'));
  const [endDate, setEndDate] = React.useState(dayjs('2024-06-09T00:00:00'));
  
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [filteredSensors, setFilteredSensors] = React.useState([]);

  React.useEffect(() => {
    const _getHistorians = async () => {
      const historians = await getHistorians();
      setHistorianList([...historians]);
    }
    _getHistorians();
  }, []);

  React.useEffect(() => {
    const _getSensors = async () => {
      const sensors = await getSensors();
      setSensorList([...sensors]);
    };
    _getSensors();
  }, []);

  React.useEffect(() => {
    var _groupsList = [];
    for(const sensor of sensorList){
      if(!_groupsList.includes(sensor['tag_group_name'])){
        _groupsList.push(sensor['tag_group_name'])
      }
    }
    setGroupsList([..._groupsList]);
  }, [sensorList]);

  React.useEffect(() => {
    const _getSensorData = async () => {
      const { header, data, latest_reading, latest_value } = await getSensorData(selectedSensor, startDate, endDate);
      setHeader(header);
      setData(data);
      setReading(latest_reading);
      setValue(latest_value);
    }
    setLoading(true);
    if(selectedSensor.name.length){
      _getSensorData();
    }
    setLoading(false);
  }, [selectedSensor, startDate, endDate]);

  useGroupColorsConfig(groupsList, setGroupColorsMap);
  useSelectedSensorConfig(
    selectedSensor,
    groupColorsMap,
    setSensorColor,
    setSensorIcon,
  );
  useFilterList(sensorList, selectedGroups, searchQuery, setFilteredSensors);


  return (
    <Box>
      <Box
        sx={{
          paddingTop: "24px",
          paddingLeft: "64px",
        }}
      >
        <Typography fontSize="1.8rem" variant="h4">
          Sensor Hub
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          paddingTop: "25px",
          paddingLeft: "64px",
          paddingRight: "64px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "40px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <StorageIcon />
            <Typography fontWeight="bold" fontSize="1.2rem" variant="h6">
              Historians
            </Typography>
          </Box>
          <Button
            sx={{
              color: "rgba(70, 67, 212, 1)",
              textTransform: "none",
              padding: 0,
              paddingTop: "1px",
              margin: 0,
            }}
            endIcon={<ArrowForwardIcon />}
          >
            <Typography fontSize="0.9rem">View all</Typography>
          </Button>
        </Box>
        <Button
          variant="outlined"
          sx={{
            color: "rgba(75, 64, 221, 1)",
            border: "1px solid rgba(114, 110, 255, 0.4)",
            textTransform: "none",
          }}
          startIcon={<AddIcon />}
        >
          Connect Historian
        </Button>
      </Box>
      <Box
        sx={{
          paddingTop: "15px",
          paddingLeft: "64px",
          paddingRight: "64px",
        }}
      >
        <Grid container spacing={"40px"}>
          {historianList.map((historian) => (
            <Grid item xs={4}>
              <HistorianCard historian={historian} selectedHistorian={selectedHistorian} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          paddingTop: "40px",
          paddingLeft: "64px",
          paddingRight: "64px",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <SensorsIcon />
          <Typography fontWeight="bold" fontSize="1.2rem" variant="h6">
            Sensors
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "2px",
            width: "60px",
            height: "30px",
            borderRadius: "20px",
            backgroundColor: "rgba(220, 220, 220, 1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography fontSize="0.9rem" fontWeight="bold">
            {
              filteredSensors.length
            }
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          height: "40px",
          paddingTop: "20px",
          paddingLeft: "64px",
          paddingRight: "64px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "50%",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "60%",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              gap: "10px",
              paddingLeft: "10px",
              boxShadow: 1,
              overflow:'hidden'
            }}
          >
            <SearchIcon fontSize="1.2rem" />
            <TextField
              onChange={(event) => setSearchQuery(event.target.value)}
              size='small'
              fullWidth={true}
              variant='filled'
              placeholder="Search..."
              autoComplete="off" // Disables browser autocomplete
            />
          </Box>
          <FilterBox
            types={groupsList}
            getOptions={setSelectedGroups}
          />
          <Box
            sx={{
              border: "1px solid lightgrey",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              boxShadow: 1,
              cursor:'pointer'
            }}
          >
            <Typography fontSize="0.9rem">Any Historian</Typography>
            <ArrowDropDownIcon fontSize="1.2rem" />
          </Box>
        </Box>
        <Button
          color="success"
          startIcon={<AddIcon />}
          endIcon={<ArrowDropDownIcon />}
          variant="contained"
        >
          Ingest
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginRight: "64px",
          marginLeft: "64px",
          marginTop: "10px",
          border: "1px solid rgba(0, 0, 0, 0.15)",
          marginBottom: "30px",
          backgroundColor: "white",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "55%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              display: "flex",
              gap: "20px",
              alignItems: "center",
              padding: "12px 16px",
            }}
          >
            <CustomCheckBox variant="all" />
            <Typography fontSize="14px" fontWeight="bold">
              Sensors
            </Typography>
          </Box>
          <Divider />
          <SensorsTable filteredSensors={filteredSensors} selectSensor={setSelectedSensor} selectedSensor={selectedSensor} groupColorsMap={groupColorsMap}/>
        </Box>
        <Divider flexItem orientation="vertical" />
        <Box
          sx={{
            width: "45%",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px",
                  alignItems: "center",
                  marginTop: "3px",
                  height: "32px",
                  width: "32px",
                  borderRadius: "4px",
                  color: "white",
                  backgroundColor: sensorColor,
                }}
              >
                {sensorIcon}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  fontSize="1.2rem"
                >
                  {selectedSensor.name}
                </Typography>
                <Typography variant="body1" fontSize="0.9rem">
                  {selectedSensor.description}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "10px",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  borderColor: "black",
                  color: "black",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                  },
                }}
                variant="outlined"
              >
                <Typography fontWeight="bold">...</Typography>
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                width: "50%",
                padding: "20px",
                paddingBottom: "15px",
                paddingTop: "15px",
              }}
            >
              <Typography fontSize="0.9rem">Latest reading</Typography>
              <Typography fontWeight="bold">
                {!loading ? reading : "..."}
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box
              sx={{
                width: "50%",
                padding: "20px",
                paddingBottom: "15px",
                paddingTop: "15px",
              }}
            >
              <Typography fontSize="0.9rem">Latest value</Typography>
              <Typography fontWeight="bold">
                {!loading ? value : "..."}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid rgba(0, 0, 0, 0.07)",
                display: "flex",
                gap: "5px",
                alignItems: "center",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <DatePickerComponent date={startDate} getDate={(date) => setStartDate(date)}/>
              <DatePickerComponent date={endDate} getDate={(date) => setEndDate(date)}/>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                sx={{
                  borderColor:
                    dataTab == "Chart"
                      ? "rgba(70, 67, 212, 1)"
                      : "rgba(0, 0, 0, 0.07)",
                  textTransform: "none",
                  color:
                    dataTab == "Chart" ? "rgba(70, 67, 212, 1)" : "black",
                  width: "80px",
                  "&:hover": {
                    borderColor:
                      dataTab == "Chart"
                        ? "rgba(70, 67, 212, 1)"
                        : "rgba(191, 190, 232, 1)",
                    backgroundColor:
                      dataTab == "Chart" ? "white" : "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                  },
                }}
                onClick={() => setDataTab("Chart")}
                variant="outlined"
                startIcon={<ShowChartIcon />}
              >
                Chart
              </Button>
              <Button
                sx={{
                  borderColor:
                    dataTab == "Data"
                      ? "rgba(70, 67, 212, 1)"
                      : "rgba(0, 0, 0, 0.07)",
                  textTransform: "none",
                  color: dataTab == "Data" ? "rgba(70, 67, 212, 1)" : "black",
                  width: "80px",
                  "&:hover": {
                    borderColor:
                      dataTab == "Data"
                        ? "rgba(70, 67, 212, 1)"
                        : "rgba(191, 190, 232, 1)",
                    backgroundColor:
                      dataTab == "Data" ? "white" : "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                  },
                }}
                onClick={() => setDataTab("Data")}
                variant="outlined"
                startIcon={<TableChartIcon />}
              >
                Data
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              height: "400px",
            }}
          >
            {selectedSensor.sensor_id !== "" && dataTab == "Chart" && (
              <TSGraph
                color={sensorColor}
                sensorId={selectedSensor.sensor_id}
                startDate={startDate}
                endDate={endDate}
              />
            )}
            {selectedSensor.sensor_id !== "" && dataTab == "Data" && (
              <TSDataTable loading={loading} header={header} data={data} />
            )}
          </Box>
          <Divider />
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "20px",
              paddingRight: "20px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                padding="10px 8px 10px 0px"
                variant="body1"
                fontWeight="bold"
              >
                Usage
              </Typography>
              <Box
                sx={{
                  borderBottom:
                    tab == "Models" ? "1px solid black" : "0px solid black",
                  display: "flex",
                  gap: "5px",
                  padding: "10px",
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor:
                      tab == "Models" ? "white" : "rgba(0, 0, 0, 0.04)", // Hover background color
                  },
                }}
                onClick={() => setTab("Models")}
              >
                <StormIcon />
                <Typography>Models</Typography>
              </Box>
              <Box
                sx={{
                  borderBottom:
                    tab == "Streams" ? "1px solid black" : "0px solid black",
                  display: "flex",
                  gap: "5px",
                  padding: "10px",
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor:
                      tab == "Streams" ? "white" : "rgba(0, 0, 0, 0.04)", // Hover background color
                  },
                }}
                onClick={() => setTab("Streams")}
              >
                <ShowChartIcon />
                <Typography>Streams</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                sx={{
                  borderColor: "rgba(70, 67, 212, 1)",
                  color: "rgba(70, 67, 212, 1)",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "rgba(70, 67, 212, 0.8)", // Hover background color
                    color: "rgba(70, 67, 212, 1)",
                  },
                  width: "120px",
                  p: 0,
                  m: 0,
                }}
                startIcon={<AddIcon />}
                endIcon={<ArrowDropDownIcon />}
                variant="text"
              >
                New
              </Button>
            </Box>
          </Box> */}
          {/* <Divider />
          <Box padding={"8px 0px"}>
            {tab == "Models" &&
              ["Vibration Forecasting (1min)", "Vibration Anomaly"].map(
                (model) => (
                  <ListItemButton>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        gap: "10px",
                        padding: "4px, 0px",
                      }}
                    >
                      <StormIcon sx={{ fill: "rgba(70, 67, 212, 1)" }} />
                      <Typography>{model}</Typography>
                    </Box>
                  </ListItemButton>
                )
              )}
            {tab == "Streams" &&
              ["Average Pressure", "Quality Time Shift"].map((model) => (
                <ListItemButton>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                      padding: "4px, 0px",
                    }}
                  >
                    <ShowChartIcon sx={{ fill: "rgba(70, 67, 212, 1)" }} />
                    <Typography>{model}</Typography>
                  </Box>
                </ListItemButton>
              ))}
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
