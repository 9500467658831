import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from "@mui/material";

import ModelHeader from '../components/model-header';
import ModelTestingConfiguration from './components/configuration';
import AnomalyDetectionTSGraph from './components/anomaly-testing-graph';
import PredictiveTSGraph from './components/predictive-testing-graph';

import { getModel, getTrainingDataset, getAnomalyModelPredictions, getPredictiveModelPredictions, getPredictiveModelFeatureImportance, getPredictiveFeatureMetricRCA } from '../../../api';

import dayjs from 'dayjs';

import { COLORS } from './constants';

export default function ModelTestingV2({}) {
    const { modelId } = useParams("modelId");
    const [model, setModel] = useState({
        model_id:null,
        name:null,
    });
    const [trainingSet, setTrainingSet] = useState(null)
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [zoomStartDate, setZoomStartDate] = useState(dayjs());
    const [zoomEndDate, setZoomEndDate] = useState(dayjs());

    const [target, setTarget] = useState([]);
    const [inputs, setInputs] = useState([]);

    const [testActuals, setTestActuals] = useState([]);
    const [testAnomalies, setTestAnomalies] = useState([]);
    const [testPredictions, setTestPredictions] = useState([]);

    const [resourceImportance, setResourceImportance] = useState(null);
    const [metricImportance, setMetricImportance] = useState(null);

    const [resourceRca, setResourceRca] = useState(null);
    const [resourceRcaExamples, setResourceRcaExamples] = useState(null);

    const [inputVisibility, setInputVisibility] = useState({});

    const [dataColors, setDataColors] = useState({});

    useEffect(() => {
        const _getModel = async () => {
            const _model = await getModel(modelId);
            setModel(_model);
            setStartDate(dayjs(_model['start_time']));
            setEndDate(dayjs(_model['end_time']));
            setZoomStartDate(dayjs(_model['start_time']));
            setZoomEndDate(dayjs(_model['end_time']));
        }
        _getModel();
    }, [modelId])

    useEffect(() => {
        const _getTrainingSet = async () => {
            const _trainingSet = await getTrainingDataset(modelId);
            setTrainingSet(_trainingSet);
        }
        _getTrainingSet();
    }, [modelId])

    useEffect(() => {
        var _target = [];
        var _inputs = [];
        var _inputVisibility = {};
        var _dataColors = {};
        if(trainingSet){
            trainingSet.sensor_config.map((config, idx) => {
                const dataType = config['data_type'];
                const idCol = `${dataType}_id`;
                const key = `${dataType}_${config[idCol]}`;
                config['key'] = key;
                _inputVisibility[key] = false;
                _dataColors[key] = COLORS[idx];
                config.variable_type === 'target' ? _target.push(config) : _inputs.push(config)
            })
        }
        setDataColors({..._dataColors});
        setInputVisibility({..._inputVisibility});
        setTarget(_target);
        setInputs(_inputs);
    }, [trainingSet])

    const getResults = async () => {
        if(model.model_objective === 'Anomaly Detection'){
            const {actuals, anomalies} = await getAnomalyModelPredictions(model, startDate, endDate);
            setZoomStartDate(startDate);
            setZoomEndDate(endDate);
            setTestActuals(actuals);
            setTestAnomalies(anomalies);
        }else if(model.model_objective === 'Predictive'){
            const {actuals, predictions} = await getPredictiveModelPredictions(model, startDate, endDate);
            setTestActuals(actuals);
            setTestPredictions(predictions);
            setZoomStartDate(startDate);
            setZoomEndDate(endDate);
        }
    }

    useEffect(() => {
        const getFeatureImportance = async () => {
            const { metric_importance, resource_importance } = await getPredictiveModelFeatureImportance(model);
            setResourceImportance(resource_importance);
            setMetricImportance(metric_importance);
        }
        const getRca = async () => {
            const { rca, rca_examples } = await getPredictiveFeatureMetricRCA(model);
            setResourceRca(rca);
            setResourceRcaExamples(rca_examples);
        }
        if(model.model_id){
            getRca();
            getFeatureImportance();
        }
    }, [model])

    useEffect(() => {
        if(model.model_id){
            getResults();
        }
    }, [model])


    return (
        <Box>
            <ModelHeader model={model} tab={'Testing'}/>
            <Box sx={{display:'flex'}}>
                <Box sx={{
                    width:'400px',
                    height:"calc(100vh - 44px)",
                }}>
                    <ModelTestingConfiguration model={model} dataColors={dataColors} inputVisibility={inputVisibility} setInputVisibility={setInputVisibility} resourceRca={resourceRca} resourceRcaExamples={resourceRcaExamples} metricImportance={metricImportance} resourceImportance={resourceImportance} target={target} inputs={inputs} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setZoomEndDate={setZoomEndDate} setZoomStartDate={setZoomStartDate} getResults={getResults}/>
                </Box>
                <Box
                    sx={{
                        width:'calc(100vw - 500px)',
                        padding:'20px'
                    }}
                >
                    {
                        model.model_objective === 'Anomaly Detection' ?
                        <AnomalyDetectionTSGraph dataColors={dataColors} inputVisibility={inputVisibility} actuals={testActuals} anomalies={testAnomalies} startDate={startDate} endDate={endDate}/> :
                        <PredictiveTSGraph target={target} dataColors={dataColors} zoomStartDate={zoomStartDate} zoomEndDate={zoomEndDate} inputs={inputs} visibility={inputVisibility} predictionData={testPredictions} targetEventData={testActuals} startDate={startDate} endDate={endDate}/>
                    }
                </Box>
            </Box>
        </Box>
    );
};