import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import { Divider, Typography, Button } from "@mui/material";

import TrainingLogs from "./components/training-logs";

import CachedIcon from "@mui/icons-material/Cached";
import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import EastIcon from "@mui/icons-material/East";
import StreamIcon from "@mui/icons-material/Stream";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import StormIcon from "@mui/icons-material/Storm";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import SettingsIcon from "@mui/icons-material/Settings";


import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import { getModelResources, getModel, getModelResults, getModelResultsStatus, trainModel } from "../../../api";
import ModelHeader from "../components/model-header";

function formatDateTime(inputString) {
  // Parse the input date string
  const date = new Date(inputString);

  // Options for date formatting
  const dateOptions = {
    month: "long",
    day: "2-digit",
    year: "numeric",
  };

  // Format the date part
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);

  // Options for time formatting
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // Format the time part
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  // Combine date and time with the desired format
  return `${formattedDate} at ${formattedTime}`;
}

export default function MLFactoryModelOverview({}) {
  const navigate = useNavigate();

  const sensorColors = {
    PV: "rgba(39, 174, 96, 1)",
    SP: "rgba(41, 128, 185, 1)",
    OUT: "rgba(231, 76, 60, 1)",
    TEST: "rgba(243, 156, 18, 1)",
  };

  const SensorTag = ({ sensor }) => {
    var sensorColor = "black";
    Object.keys(sensorColors).forEach((type) => {
      if (sensor.name && sensor.name.includes(type)) {
        sensorColor = sensorColors[type];
      }
    });

    var icon = <SensorsIcon sx={{height:'10px', width:'10px', fill:'orange'}}/>;
    if(sensor.data_type === 'event'){
      icon = <EventIcon sx={{height:'10px', width:'10px', fill:'purple'}}/>;
    }else if(sensor.data_type === 'deployment'){
      icon = <RocketLaunchIcon sx={{height:'10px', width:'10px', fill:'green'}}/>;
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{ display:'flex', justifyContent:'center', alignItems:'center', border:'1px solid lightgrey', borderRadius:'5px', padding:'2px' }}
        >
          {icon}
        </Box>
        <Typography variant="body2">
          {model.model_type === "Generalized"
            ? sensor.name
            : sensor.name}
        </Typography>
      </Box>
    );
  };

  const StreamTag = ({ stream, idx }) => {
    var sensorColor = Object.values(sensorColors)[idx] ?? "black";

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{ height: "10px", width: "10px", backgroundColor: sensorColor }}
        ></Box>
        <Typography variant="body2">{stream.name}</Typography>
        <Divider orientation="vertical" flexItem />
      </Box>
    );
  };

  const { modelId } = useParams("modelId");

  const [model, setModel] = React.useState({
    model_id: "",
    name: "",
    model_type: "",
    model_objective: "",
    training_status: "",
    version: "",
  });

  React.useEffect(() => {
    const _getModel = async () => {
      const _model = await getModel(modelId);
      setModel({ ..._model });
    };
    _getModel();
  }, [modelId]);

  const [hasResults, setHasResults] = React.useState(false);
  const [trainResults, setTrainResults] = React.useState(null);
  React.useEffect(() => {
    const getResults = async () => {
      const modelResults = await getModelResults(model);
      if(modelResults.training_time){
        setHasResults(true);
      }
      setTrainResults(modelResults);
    };
    if(model.model_id){
      getResults();
    }
  }, [model]);

  const [inputs, setInputs] = React.useState(null);
  const [output, setOutput] = React.useState(null);
  const [modelData, setModelData] = React.useState({
    sensors: [],
    streams: [],
  });
  React.useEffect(() => {
    if (model.model_type === "Targeted") {
      var _inputs = [];
      var _output = null;
      modelData["sensors"].forEach((s) => {
        if (s.variable_type === "input") {
          _inputs.push(s);
        } else {
          _output = s;
        }
      });
      setOutput({ ..._output });
      setInputs([..._inputs]);
    } else {
      setInputs([...modelData.sensors]);
    }
  }, [modelData]);

  React.useEffect(() => {
    const getResources = async () => {
      const modelData = await getModelResources(model);
      setModelData({ ...modelData });
    };

    if (model.model_id !== "") {
      getResources();
    }
  }, [model]);

  const [jobStarted, setJobStarted] = React.useState(false);
  const startTrainingJob = async () => {
    try {
      setJobStarted(true);
      const result = await trainModel(model.model_id);
      setJobStarted(false);
    } catch (error) {
      setJobStarted(false);
      console.error("Error:", error);
    }
  };

  console.log(inputs);

  return (
    <Box sx={{}}>
      <ModelHeader tab={'Overview'} model={model}/>
      <Divider />
      <Box
        sx={{
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            height: "70px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "20px",
            paddingLeft: "64px",
            paddingRight: "64px",
            paddingBottom: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <Box
              sx={{
                paddingTop: "20px",
              }}
            >
              <Typography
                fontSize={"1.7rem"}
                fontWeight="bold"
                variant="h5"
              >
                {model.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  paddingTop: "5px",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={"1.1rem"}
                  color="rgba(79, 79, 79, 1)"
                  variant="body2"
                >
                  Objective
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "2px",
                    alignItems: "center",
                  }}
                >
                  {model.model_objective == "Forecasting" ? (
                    <StormIcon sx={{ color: "rgba(33, 150, 122, 1)" }} />
                  ) : model.model_objective == "Optimization" ? (
                    <TroubleshootIcon sx={{ color: "#0047AB" }} />
                  ) : (
                    <CircleNotificationsIcon
                      sx={{ color: "rgba(199, 94, 0, 1)" }}
                    />
                  )}
                  <Typography
                    fontSize={"1.1rem"}
                    color={
                      model.model_objective == "Forecasting"
                        ? "rgba(33, 150, 122, 1)"
                        : model.model_objective == "Optimization"
                        ? "#0047AB"
                        : "rgba(199, 94, 0, 1)"
                    }
                    variant="body2"
                  >
                    {model.model_objective}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor:
                  model.model_type == "Generalized"
                    ? "#F2F2F2"
                    : "rgba(47, 128, 237, 0.1)",
                padding: "5px",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
            >
              <Typography fontSize="0.9rem">{model.model_type}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider orientation="horizontal" />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "35%" }}>
          <Box sx={{ margin: "50px", backgroundColor: "white" }}>
            <Box
              sx={{
                height: "4px",
                background: "linear-gradient(45deg, #1AEFAF, #605DFE)",
              }}
            ></Box>
            <Box
              sx={{
                padding: "20px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                borderTop: "0px solid white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Box shadow
              }}
            >
              <Box sx={{ paddingTop: "10px" }}>
                <Typography fontWeight="bold" fontSize="0.9rem">
                  Model Checklist
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                {hasResults && <CheckBoxIcon sx={{ fill: "#14AE5C" }} />}
                {!hasResults && (
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #4643D4",
                      height: "20px",
                      width: "19px",
                      pl: "2px",
                      pr: "2px",
                      display: "flex",
                      borderRadius: "3px",
                      marginTop: "2px",
                    }}
                  >
                    <Typography fontWeight="bold" color="#4643D4">
                      1
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography fontSize="0.9rem">
                    Configure and train model
                  </Typography>
                  <Typography color="#24252A" fontSize="0.8rem">
                    Specify model parameters such as the context size and
                    set the training strategy
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                {/* {hasResults && (
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #4643D4",
                      height: "20px",
                      width: "45px",
                      display: "flex",
                      borderRadius: "3px",
                      marginTop: "2px",
                    }}
                  >
                    <Typography fontWeight="bold" color="#4643D4">
                      2
                    </Typography>
                  </Box>
                )}
                {!hasResults && (
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid rgba(0, 0, 0, 0.35)",
                      height: "20px",
                      width: "45px",
                      display: "flex",
                      borderRadius: "3px",
                      marginTop: "2px",
                    }}
                  >
                    <Typography fontWeight="bold" color="#4F4F4F">
                      2
                    </Typography>
                  </Box>
                )} */}
                <CheckBoxIcon sx={{ fill: "#14AE5C" }} />
                <Box>
                  <Typography fontSize="0.9rem">
                    Explore the model sandbox to view performance
                  </Typography>
                  <Typography color="#24252A" fontSize="0.8rem">
                    The sandbox allows you to investigate how the model is
                    performing for specific sensors. You will be able to
                    simulate results before deploying the model.
                  </Typography>
                  <Button
                    endIcon={<EastIcon />}
                    variant="contained"
                    sx={{
                      mt: "10px",
                      p: "5px",
                      fontSize: "0.8rem",
                      textTransform: "none",
                      backgroundColor: "#24252A",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "black", // Hover background color
                      },
                    }}
                  >
                    Open Sandbox
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                {/* <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid rgba(0, 0, 0, 0.35)",
                    height: "20px",
                    width: "18px",
                    display: "flex",
                    borderRadius: "3px",
                    marginTop: "2px",
                    pr: "2px",
                    pl: "2px",
                  }}
                >
                  <Typography fontWeight="bold" color="#4F4F4F">
                    3
                  </Typography>
                </Box> */}
                <CheckBoxIcon sx={{ fill: "#14AE5C" }} />
                <Box>
                  <Typography fontSize="0.9rem">Deploy model</Typography>
                  <Typography color="#24252A" fontSize="0.8rem">
                    Call model results from a live endpoint or writeback the
                    results to a historian
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "65%" }}>
          <Box
            sx={{
              padding: "50px",
              paddingLeft: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontSize="1.4rem" fontWeight="bold">
                Model summary
              </Typography>
              <Button
                sx={{
                  color: "rgba(75, 64, 221, 1)",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "rgba(114, 110, 255, 0.1)", // Hover background color
                  },
                }}
                component={Link}
                to={
                  model.model_type == "Targeted"
                    ? "/ml-factory/model-builder/" +
                      model.model_id
                    : "/ml-factory/model-builder/training-data/generalized/" +
                      model.model_id
                }
                variant="text"
                endIcon={<SettingsIcon />}
              >
                View Configuration
              </Button>
            </Box>
            <Box
              sx={{
                marginTop: "10px",
                padding: "10px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                backgroundColor: "white",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  padding: "10px",
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  Data
                </Typography>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Inputs
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "190px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                      flexWrap: "wrap",
                      maxHeight: "130px",
                      overflow: "auto",
                    }}
                  >
                    {(inputs && inputs.length > 0) &&
                      inputs.map((sensor) => <SensorTag sensor={sensor} />)}
                  </Box>
                </Box>
                {output && (
                  <Box
                    sx={{
                      paddingTop: "15px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "130px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        color="rgba(79, 79, 79, 1)"
                        variant="body2"
                      >
                        Target
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "190px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {output && <SensorTag sensor={output} />}
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Training time range
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    {model.start_time && (
                      <Typography variant="body2">
                        {formatDateTime(model.start_time)}{" "}
                        <span style={{ color: "rgba(79, 79, 79, 1)" }}>
                          to
                        </span>{" "}
                        {formatDateTime(model.end_time)}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider flexItem orientation="vertical" />
              <Box
                sx={{
                  padding: "20px",
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  Metadata
                </Typography>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "110px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Creator
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">You</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "110px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Last modified
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {formatDateTime(model.last_updated)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "110px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Last trained
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    {(trainResults && trainResults.training_time) && (
                      <Typography variant="body2">
                        {formatDateTime(trainResults.training_time)}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "110px",
                      display: "flex",
                    }}
                  >
                    <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                      Created on
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      {formatDateTime(model.creation_time)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "50px",
              }}
            >
              <Typography fontSize="1.4rem" fontWeight="bold">
                Training
              </Typography>
              <Button
                sx={{
                  backgroundColor: "rgba(55, 70, 91, 0.08)",
                  color: "rgba(33, 43, 56, 1)",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "rgba(55, 70, 91, 0.2)", // Hover background color
                  },
                }}
                onClick={startTrainingJob}
                disabled={jobStarted}
                // component={Link}
                // to={model.model_type == 'Targeted' ? '/ml-factory/model-builder/training-data/' + model.model_id : '/ml-factory/model-builder/training-data/generalized/' + model.model_id}
                variant="text"
                endIcon={<CachedIcon />}
              >
                {hasResults ? "Re-train model" : "Train model"}
              </Button>
            </Box>
            <Box
              sx={{
                marginTop: "10px",
                padding: "20px",
                backgroundColor: "white",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Box shadow
              }}
            >
              <TrainingLogs jobStarted={jobStarted} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
