import {
    Alert,
    AlertTitle,
    Box,
    IconButton,
    Divider,
    Typography,
    TextField,
    Button,
} from "@mui/material";

import { COLORS } from "../constants";

import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';


export default function VariableCard({ config, idx, resourceImportance, dataColors }){
    const dataType = config['data_type'];
    const idCol = `${dataType}_id`;
    const key = `${dataType}_${config[idCol]}`;

    const importance = (resourceImportance ? resourceImportance[key] : 0) * 100;
  
    return (
      <Box
        sx={{
          margin: "8px",
          padding: "12px 16px",
          backgroundColor: "white",
          borderRadius: "4px",
          border: "1px solid rgba(0, 0, 0, 0.15)",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.07)",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
          <Box
              sx={{
                // width:'20px',
                // height:'20px',
                borderRadius:'5px',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                padding:'2px',
                backgroundColor:dataColors[key]
              }}
            >
              {
                config.data_type === 'sensor' ? <SensorsIcon sx={{height:'20px', fill:'white'}}/> :
                config.data_type === 'event' ? <EventIcon sx={{height:'20px', fill:'white'}}/> :
                <RocketLaunchIcon sx={{height:'20px', fill:'white'}}/> 
              }

            </Box>
            <Box>
              {
                config.data_type === 'sensor' ? 
                <Typography                
                  variant="overline"
                  color={"black"}
                >
                  {config.name} ({config.tag_id})
                </Typography> :
                <Typography                
                  fontSize='12px'
                  color={"black"}
                >
                  {config.name}
                </Typography>
              }
              <Typography
                fontSize='12px'
                color={"black"}
              >
                {config.description}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <IconButton sx={{ m: 0, p: 0 }}>
              {config.variable_type === "input" && <VisibilityOffIcon />}
              {config.variable_type === "target" && <VisibilityIcon />}
            </IconButton> */}
          </Box>
        </Box>
      </Box>
    );
  };