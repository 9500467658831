import { useEffect, useState } from 'react';

import { Box, Typography, Divider, TableContainer, TableHead, TableBody, TableCell } from "@mui/material";
import VariableCard from "./variable-card";
import VariableRow from "./variable-row";
import RCARow from './rca-row';

export default function InputsSection({dataColors, setStartDate, setEndDate, inputVisibility, setInputVisibility, resourceRca, resourceRcaExamples, resourceImportance, metricImportance, inputs, model}){
    const [sortedInputs, setSortedInputs] = useState([]);

    const [selectedInput, setSelectedInput] = useState(null);
    const [tableRows, setTableRows] = useState([]);

    useEffect(() => {
        if(resourceImportance && (inputs.length > 0)){
            var _inputs = inputs;
            _inputs.sort((a,b) => parseFloat(resourceImportance[b.key]) - parseFloat(resourceImportance[a.key]));
            setSortedInputs(_inputs);
        }
    }, [inputs, resourceImportance])

    const handleInsightsButton = (config) => {
        if(selectedInput && selectedInput.key === config.key){
            setSelectedInput(null);
        }else{
            setSelectedInput(config);
        }
    }

    useEffect(() => {
        var _rows = [];
        console.log(metricImportance);
        sortedInputs.forEach((i, idx) => {
            _rows.push(
                <VariableRow
                    dataColors={dataColors}
                    visibility={inputVisibility}
                    setVisibility={setInputVisibility}
                    config={i}
                    idx={1 + idx}
                    resourceImportance={resourceImportance}
                    handleInsightsButton={handleInsightsButton}
                />
            )
            if(resourceRca && resourceRcaExamples && selectedInput && (i.key === selectedInput.key)){
                _rows.push(
                    <RCARow
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        metricImportance={metricImportance[selectedInput.key]}
                        rca={resourceRca[selectedInput.key]}
                        rcaExamples={resourceRcaExamples[selectedInput.key]}
                    />
                )
            }
        })
        setTableRows(_rows);
    }, [sortedInputs, selectedInput, inputVisibility, resourceRca, resourceRcaExamples])

    return (
        <Box
            sx={{
            borderBottom: "1px solid lightgrey",
            backgroundColor: "rgba(247, 247, 247, 1)",
            }}
        >
            <Box
            sx={{
                backgroundColor: "white",
                height: "48px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
                gap: "20px",
            }}
            >
            <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                Inputs
            </Typography>
            </Box>
            <Divider />
            <TableContainer>
                <TableHead>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                        Resource
                    </TableCell>
                    <TableCell>
                        Feature Importance
                    </TableCell>
                </TableHead>
                <TableBody>
                    {
                        tableRows.map((i) => (
                            i
                        ))
                    }
                </TableBody>
            </TableContainer>
        </Box>
    );
}