import { useEffect, useState } from 'react';

import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Box, Typography, Divider, Button } from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import SensorRow from './sensor-row';
import DeploymentRow from './deployment-row';
import EventRow from './event-row';
import FilterBox from './filter-box';
import Filters from './filters';

export default function SensorSelectionModal({anchor, placement, deploymentList, eventsList, sensorList, handleImportModal, handleSensorSelection}){
    const [filteredSensors, setFilteredSensors] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [filteredDeployments, setFilteredDeployments] = useState([]);
  
    const open = Boolean(anchor);

    const [selectedSensors, setSelectedSensors] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [selectedDeployments, setSelectedDeployments] = useState([]);

    const handleSensorRowSelect = (sensor) => {
      var selected = [...selectedSensors];
      if(selectedSensors.map((_sensor) => (_sensor.sensor_id)).includes(sensor.sensor_id)){
        selected = selected.filter(_sensor => _sensor.sensor_id !== sensor.sensor_id);
      }else{
        selected.push(sensor);
      }
      setSelectedSensors([...selected]);
    }

    const handleEventRowSelect = (event) => {
      var selected = [...selectedEvents];
      if(selectedEvents.map((_event) => (_event.event_id)).includes(event.event_id)){
        selected = selected.filter(_event => _event.event_id !== event.event_id);
      }else{
        selected.push(event);
      }
      setSelectedEvents([...selected]);
    }

    const handleDeploymentRowSelect = (event) => {
      var selected = [...selectedDeployments];
      if(selectedDeployments.map((_deployment) => (_deployment.model_id)).includes(event.model_id)){
        selected = selected.filter(_deployment => _deployment.model_id !== event.model_id);
      }else{
        selected.push(event);
      }
      setSelectedDeployments([...selected]);
    }

    const handleImportButton = () => {
        if(tab === 'Sensors'){
          handleSensorSelection(selectedSensors, 'sensor');
        }else if(tab === 'Events'){
          handleSensorSelection(selectedEvents, 'event');
        }else if(tab === 'Deployments'){
          handleSensorSelection(selectedDeployments, 'deployment');
        }
        setSelectedSensors([]);
        setSelectedEvents([]);
        setSelectedDeployments([]);
    }

    const [tab, setTab] = useState('Sensors');

    const DataTab = ({name}) => {
      return (
        <Box
          sx={{
            background: tab === name ? 'rgba(75, 64, 221, 1)' : 'lightgrey',
            color:'white',
            padding:'10px',
            pt:'2px',
            pb:'2px',
            borderRadius:'10px',
            cursor:'pointer',
            '&:hover':{
              backgroundColor:'rgba(75, 64, 221, 0.5)'
            },
          }}
          onClick={() => setTab(name)}
        >
          <Typography>{name}</Typography>
        </Box>
      );
    }

    const getFilterListTypes = (dataList, column) => {
      var types = [];
      for(const data of dataList){
        if(!types.includes(data[column])){
          types.push(data[column])
        }
      }
      return types;
    }

    const [filtersConfig, setFiltersConfig] = useState({
      sensors: [],
      events: [],
      deployments: [],
    });

    const [filterSelections, setFilterSelections] = useState({
      sensors: {},
      events: {},
      deployments: {},
    });

    const handleSensorFilterSelection = (selections, col) => {
      var config = filterSelections;
      config.sensors[col] = selections;
      setFilterSelections({...config});
    }

    useEffect(() => {
      var config = filtersConfig;
      const filtersMap = {
        sensors: ['measuring_point', 'tag_group_name', 'historian_name'],
        events: ['source_name'],
        deployments: ['model_objective', 'model_type'],
      }
      config.sensors = [];
      for(const col of filtersMap.sensors){
        const types = getFilterListTypes(sensorList, col);
        config['sensors'].push(<FilterBox name={col.replace('_', ' ').toUpperCase()} col={col} types={types} getOptions={handleSensorFilterSelection} filterSelections={filterSelections.sensors}/>)
      }
      config.events = [];
      for(const col of filtersMap.events){
        const types = getFilterListTypes(eventsList, col);
        config['events'].push(<FilterBox name={col.replace('_', ' ').toUpperCase()} col={col} types={types} getOptions={handleSensorFilterSelection}/>)
      }
      config.deployments = [];
      for(const col of filtersMap.deployments){
        const types = getFilterListTypes(deploymentList, col);
        config['deployments'].push(<FilterBox name={col.replace('_', ' ').toUpperCase()} col={col} types={types} getOptions={handleDeploymentRowSelect}/>)
      }
      setFiltersConfig({...config});
    }, [sensorList, eventsList])

    useEffect(() => {
      var _filteredSensors = [];
      const sensorColumns = Object.keys(filterSelections.sensors)
      for(const sensor of sensorList){
        for(const column of sensorColumns){
          if(filterSelections.sensors[column].length == 0 || filterSelections.sensors[column].includes(sensor[column])){
            _filteredSensors.push(sensor);
          }
        }
      }
      var _filteredEvents = [];
      const eventColumns = Object.keys(filterSelections.sensors)
      for(const event of eventsList){
        for(const column of eventColumns){
          if(filterSelections.events[column] && (filterSelections.events[column].length == 0 || filterSelections.events[column].includes(event[column]))){
            _filteredEvents.push(event);
          }
        }
      }
      var _filteredDeployments = [];
      const deploymentColumns = Object.keys(filterSelections.deployments)
      for(const deployment of deploymentList){
        for(const column of deploymentColumns){
          if(filterSelections.deployments[column] && (filterSelections.deployments[column].length == 0 || filterSelections.deployments[column].includes(deployment[column]))){
            _filteredDeployments.push(deployment);
          }
        }
      }
      setSelectedSensors([]);
      setSelectedEvents([]);
      setSelectedDeployments([]);
      setFilteredSensors(_filteredSensors);
      setFilteredEvents(_filteredEvents);
      setFilteredDeployments(_filteredDeployments);
    }, [filterSelections])

    return (
        <Box
          sx={{
            position: 'relative',
            backgroundColor: 'white',
            border: '1px solid rgba(0, 0, 0, 0.15)',
            borderRadius: '8px',
          }}
        >
          <Box
            sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'center'
            }}
          >
            <Box
              sx={{
                display:'flex',
                gap:'10px',
                padding:'10px'
              }}
            >
              <DataTab name={'Sensors'}/>
              <DataTab name={'Events'}/>
              <DataTab name={'Deployments'}/>
            </Box>
            <Box
              sx={{
                mr:'10px'
              }}
            >
              <Filters filterBoxList={filtersConfig[tab.toLowerCase()]}/>
            </Box>
          </Box>
          <Divider/>
          <Box
            sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'center',
            }}
          >
            <Box
              sx={{
                display:'flex',
                alignItems:'center',
                gap:'5px',
                padding:'10px'
              }}
            >
              <SearchIcon  sx={{fill:'black'}}/>
              <Typography color='rgba(130, 130, 130, 1)'>
                Search...
              </Typography>
            </Box>
            <Box
              sx={{
                display:'flex',
                alignItems:'center',
                gap:'5px',
                paddingRight:'5px',
              }}
            >
              <Divider flexItem orientation='vertical'/>
              <CloseIcon onClick={handleImportModal} sx={{fill:'rgba(36, 37, 42, 1)'}}/>
            </Box>
          </Box>
          <Divider/>
          <Box
            sx={{
              height:'200px',
              overflow:'scroll'
            }}
          >
            {
              tab === 'Sensors' ?
              (
                filteredSensors.length > 0 ?
                filteredSensors.map((sensor) => (
                  <SensorRow sensor={sensor} selectedSensors={selectedSensors} selectSensor={handleSensorRowSelect}/>
                )) :
                sensorList.map((sensor) => (
                  <SensorRow sensor={sensor} selectedSensors={selectedSensors} selectSensor={handleSensorRowSelect}/>
                ))
              ) : tab === 'Events' ?
              (
                filteredEvents.length > 0 ?
                filteredEvents.map((event) => (
                  <EventRow event={event} selectedEvents={selectedEvents} selectEvent={handleEventRowSelect}/>
                )) :
                eventsList.map((event) => (
                  <EventRow event={event} selectedEvents={selectedEvents} selectEvent={handleEventRowSelect}/>
                ))
              )  :
              (
                filteredDeployments.length > 0 ?
                filteredDeployments.map((deployment) => (
                  <DeploymentRow deployment={deployment} selectedDeployments={selectedDeployments} selectDeployment={handleDeploymentRowSelect}/>
                )) :
                deploymentList.map((deployment) => (
                  <DeploymentRow deployment={deployment} selectedDeployments={selectedDeployments} selectDeployment={handleDeploymentRowSelect}/>
                ))
              ) 
            }
          </Box>
          <Box
            sx={{
              display:'flex',
              justifyContent:'right',
              alignItems:'center',
              padding:'10px'
            }}
          >
            <Button
              sx={{
                  textTransform:'none',
                  color:'rgba(75, 64, 221, 1)',
                  borderColor:'rgba(75, 64, 221, 1)',
              }}
              variant='outlined'
              startIcon={<AddIcon/>}
              onClick={handleImportButton}
              disabled={(tab === 'Sensors' && selectedSensors.length === 0) || (tab === 'Events' && selectedEvents.length === 0)}
            >
                Import {tab === 'Sensors' ? selectedSensors.length : tab === 'Events' ?  selectedEvents.length : selectedDeployments.length} {tab === 'Sensors' ? 'Sensor(s)' : tab === 'Events' ? 'Event(s)' : 'Deployment(s)'}
            </Button>
          </Box>
        </Box>
    )
}