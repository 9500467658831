import * as React from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Divider,
  Typography,
  Button,
  LinearProgress,
  TextField,
  ListItemButton,
} from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function HistorianCard({ historian, selectedHistorian }) {
    var isSelected = false;
    if (selectedHistorian && selectedHistorian == historian.name) {
      isSelected = true;
    }

    const ingestionProgress = Math.ceil(
      100 * (86 / historian["total_sensors"])
    );

    return (
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.1)", // Adjust the blur and shadow strength
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          height: "140px",
          "&:hover": {
            border: "1px solid rgba(70, 67, 212, 0.5)", // New border color on hover
            "& .historianName": {
              color: "rgb(70, 67, 212)", // Change typography color on hover
            },
          },
        }}
      >
        <Box
          sx={{
            padding: "15px",
            paddingLeft: "20px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            className="historianName"
            fontWeight="bold"
            variant="body1"
            fontSize="0.9rem"
          >
            {historian.name}
          </Typography>
          <Box
            sx={{
              height: "48px",
            }}
          >
            <Typography
              variant="body1"
              fontSize="0.9rem"
              color="rgba(79, 79, 79, 1)"
            >
              {historian.description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <SensorsIcon />
              <Typography paddingTop="2px" fontSize="0.9rem">
                <span style={{ fontWeight: "bold" }}>
                  {historian["ingested_sensors"]}{" "}
                </span>
                of {historian["total_sensors"]} sensors ingested
              </Typography>
            </Box>
            {ingestionProgress == 100 ? (
              <CheckCircleIcon
                sx={{
                  fill: "rgba(33, 150, 83, 1)",
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "90px",
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={ingestionProgress} // Set the progress value here (0-100)
                  sx={{
                    height: 10, // Set the height of the progress bar
                    borderRadius: 5, // Optional: to add rounded corners
                    backgroundColor: "rgba(224, 224, 224, 1)", // Background color
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "rgba(33, 150, 83, 1)", // Progress bar color
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
};