import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import "chartjs-adapter-dayjs-4";

import { getSensorConfigGraphData } from "../../../../../api";
import { COLORS } from "../../constants"

import dayjs from 'dayjs';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

const TSGraph = ({sensorConfig, graphStatus, visibility, startDate, endDate, sensorColors, samplingFrequency, samplingFrequencyUnits}) => {
  const [sensorData, setSensorData] = React.useState([]);
  const [eventData, setEventData] = React.useState([]);
  const [recordData, setRecordData] = React.useState([]);

  const [hoverEvent, setHoverEvent] = React.useState(null);

  const [chartData, setChartData] = React.useState({
    datasets: [
    ]
  });
  const [options, setOptions] = React.useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'time', // Time series on the X axis
        min:startDate.toISOString(),
        max:endDate.toISOString()
      },
    },
  });

  React.useEffect(() => {
    const getSensors = async () => {
      const { data } = await getSensorConfigGraphData(sensorConfig, visibility, startDate, endDate, samplingFrequency, samplingFrequencyUnits);
      var _sensorData = []
      var _eventData = []
      var _recordData = []

      data.forEach((d) => {
        if(d.data_type === 'sensor'){
          _sensorData.push(d);
        }else if(d.data_type === 'event'){
          _eventData.push(d);
        }else if(d.data_type === 'deployment' && d.graph_type === 'record'){
          _recordData.push(d);
        }
      })

      setEventData([..._eventData]);
      setRecordData([..._recordData]);
      setSensorData([..._sensorData]);
    };
    if(sensorConfig && Object.keys(visibility).length){
      getSensors();
    }
  }, [sensorConfig, visibility, graphStatus, startDate, endDate])

  React.useEffect(() => {
    if(sensorConfig && sensorData && eventData){
      var __chartData = {
        datasets: [
          ...sensorData.map((data, idx) => (
            {
              label: data.title,
              data: data.values,
              fill: false,
              borderColor: sensorColors[`${data.data_type}_${data.id}`],
              tension: 0.1,
              yAxisID: `y${idx + 1}`,
              pointRadius:0,
              hidden:!visibility[`${data.data_type}_${data.id}`]
            }
          )),
          ...recordData.map((record, idx) => (
            {
              label: record.title,
              data: record.values.map((r) => ({x:r.time, y:r.value})),
              pointRadius:10,
              backgroundColor: sensorColors[`${record.data_type}_${record.id}`],
              type: 'scatter',
              showLine: false,
              yAxisID: `y${sensorData.length + idx + 1}`,
              hidden:!visibility[`${record.data_type}_${record.id}`]
            }
          ))
        ]
      };

      var __options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          title: {
            display: false,
          },
          annotation: {
            annotations: eventData.flatMap((event, idx) => (
              event.values.map((e) => (
                {
                  type: 'box', // Draw a rectangle
                  xMin: e.start_time, // Start time of the e
                  xMax: e.end_time, // End time of the event
                  backgroundColor: sensorColors[`${event.data_type}_${event.id}`],
                  borderColor: sensorColors[`${event.data_type}_${event.id}`],
                  
                  // borderWidth: 2,
                }
              ))
            )),
          },
        },
        scales: {
          x: {
            type: 'time', // Time series on the X axis
            min:startDate.toISOString(),
            max:endDate.toISOString()
          },
        },
        // onHover: (event, chartElement) => {
        //   const time = dayjs(event.chart.scales.x.getValueForPixel(event.x))
        //   var inBox = false;
        //   for(const event of eventData){
        //     const start = dayjs(event.start_time);
        //     const end = dayjs(event.end_time);
        //     if((time >= start) && (time <= end)){
        //       setHoverEvent(event);
        //       inBox = true;
        //       break;
        //     }
        //   }
        // },
      };

      sensorData.forEach((data, idx) => {
        __options.scales[`y${idx + 1}`] = {
          type: 'linear',
          display: visibility[data.title],
          position: 'left',
          border: {
              color: COLORS[idx]
          },
        }
      })

      recordData.forEach((data, idx) => {
        __options.scales[`y${sensorData.length + idx + 1}`] = {
          type: 'linear',
          display: visibility[data.title],
          position: 'left',
          border: {
              color: COLORS[sensorData.length + idx]
          },
        }
      })

      setChartData({...__chartData});
      setOptions({...__options});
    }
  }, [sensorConfig, sensorData, eventData, visibility, startDate, endDate])

  return (
    <div style={{height:'100%', width:'100%', paddingTop:'10px'}}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default TSGraph;
