import { Typography, Box, IconButton, Dialog } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import TypedText from './typed-text';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactMarkdown from 'react-markdown';

import AlertNotification from './alert';

import LoadingSpinner from '../../../../../components/loading-spinner';

const INTRO_TEXT = "Hey there!\nAsk me questions about your machine data!"

export default function MonitorChatBot({alerts, setAlerts, setAlertConfig, alertConfig, maxeInitStatus, maxeResponse, maxeResponseIdx, conversation, messages, idx}){    
    const BotMessage = ({message, typeText, idx}) => {
        return (
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    gap:'10px'
                }}
            >
                <Box
                    sx={{
                        background: 'linear-gradient(154.32deg, #FCFCFD 15.25%, #F6F5F9 93.61%)',
                        borderRadius: '8px',
                        border:'1px solid rgba(0, 0, 0, 0.15)',
                        pl:'10px',
                        pr:'10px',
                        width:'90%'
                    }}
                >
                    {
                        typeText &&
                        <TypedText text={message} idx={idx}/>
                    }
                    {
                        !typeText &&
                        <ReactMarkdown>{message}</ReactMarkdown>
                    }
                </Box>
                <Box
                sx={{
                    width:'10%',
                    alignItems:'center',
                    justifyContent:'end'
                }}
                >
                    {
                        !typeText &&
                        <IconButton sx={{p:0, m:0}}><ContentCopyIcon sx={{fill:'rgb(90, 90, 90)'}}/></IconButton>
                    }
                </Box>
            </Box>
        )
    }

    const UserMessage = ({message}) => {
        return (
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center'
                }}
            >
                <Box
                    sx={{
                        width:'10%'
                    }}
                >
                </Box>
                <Box
                    sx={{
                        background: 'rgb(36, 37, 42)',
                        borderRadius: '8px',
                        pl:'10px',
                        pr:'10px',
                        pt:'15px',
                        pb:'15px',
                        width:'90%'
                    }}
                >
                    <span style={{fontSize:'15px', color:'white'}}>{message}</span>
                </Box>

            </Box>
        )
    }

    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [maxeResponseIdx, conversation, alertConfig]);

    const handleAlertCreate = (alert) => {
        var _alerts = [...alerts];
        _alerts.push(alert);
        setAlerts([..._alerts]);
        setAlertConfig(null);
    }

    return (
        !maxeInitStatus ?
        <Box
            sx={{
                height:'70vh',
                width:'100%',
                alignItems:'center',
                justifyContent:'center',
                display:'flex',
                flexDirection:'column',
                gap:'20px'
            }}
        >
            <Typography fontWeight='bold' variant='overline' fontSize='16px'>Initializing...</Typography>
            <LoadingSpinner isLoading={true}/>
        </Box>
        :
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                gap:'15px',
                overflow:'scroll',
            }}
        >
            <BotMessage message={INTRO_TEXT} typeText={false}/>
            {conversation.map((message) => (
                (message.display === 1 && message.role === 'user') ? <UserMessage message={message.content}/> : (message.display === 1) ? <BotMessage message={message.content} typeText={false}/> : <Box></Box>
            ))}
            {
                (maxeResponseIdx > -1) &&
                <BotMessage message={maxeResponse.content} typeText={true} idx={maxeResponseIdx} />
            }
            {
                alertConfig &&
                <Dialog sx={{p:0, m:0}} open={Boolean(alertConfig)}>
                    <AlertNotification setAlertConfig={setAlertConfig} alert={alertConfig} onGenerateAlert={handleAlertCreate}/>
                </Dialog>
            }
            <div ref={messagesEndRef} />
        </Box>
    )
}