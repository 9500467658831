import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import "chartjs-adapter-dayjs-4";
import zoomPlugin from 'chartjs-plugin-zoom';

import { COLORS } from "../constants"

import { getSensorConfigGraphData } from '../../../../api';

import dayjs from 'dayjs';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin, zoomPlugin);

const PredictiveTSGraph = ({inputs, visibility, zoomStartDate, zoomEndDate, startDate, endDate, predictionData, targetEventData, target, dataColors}) => {
  const [sensorData, setSensorData] = React.useState([]);
  const [eventData, setEventData] = React.useState([]);
  const [recordData, setRecordData] = React.useState([]);

  React.useEffect(() => {
    const getSensors = async () => {
      const { data } = await getSensorConfigGraphData(inputs, visibility, startDate, endDate);
      var _sensorData = []
      var _eventData = []
      var _recordData = []

      data.forEach((d) => {
        if(d.data_type === 'sensor'){
          _sensorData.push(d);
        }else if(d.data_type === 'event'){
          _eventData.push(d);
        }else if(d.data_type === 'deployment' && d.graph_type === 'record'){
          _recordData.push(d);
        }
      })

      setEventData([..._eventData]);
      setRecordData([..._recordData]);
      setSensorData([..._sensorData]);
    };
    if((inputs.length > 0) && visibility && Object.keys(visibility).length){
      getSensors();
    }
  }, [inputs, visibility, startDate, endDate])


  const [chartData, setChartData] = useState(null);
  const [options, setOptions] = useState(null);
  useEffect(() => {
    if(predictionData && eventData && (target.length > 0)){
      const targetDataType = target[0]['data_type'];
      const targetId = target[0][targetDataType + '_id'];
      const targetKey = `${targetDataType}_${targetId}`;

      var _chartData = {
        datasets: [
            {
              label:target[0].name,
              borderColor:dataColors[targetKey],
            },
            {
            //   label: data.title,
              label:'% Chance',
              data: predictionData.map((data, idx) => ({x:data.time, y:data.probability})),
              fill: false,
              borderColor: 'grey',
              tension: 0.1,
              yAxisID: 'y',
              // pointBackgroundColor: predData.map((data) => (data.correct ? 'green' : 'red'))
            },
            ...sensorData.map((data, idx) => (
              {
                label: data.title,
                data: data.values,
                fill: false,
                borderColor: dataColors[`${data.data_type}_${data.id}`],
                tension: 0.1,
                yAxisID: `y${idx + 1}`,
                pointRadius:0,
                hidden:!visibility[`${data.data_type}_${data.id}`]
              }
            )),
            ...recordData.map((record, idx) => (
              {
                label: record.title,
                data: record.values.map((r) => ({x:r.time, y:r.value})),
                pointRadius:10,
                borderColor: dataColors[`${record.data_type}_${record.id}`],
                type: 'scatter',
                showLine: false,
                yAxisID: `y${sensorData.length + idx + 1}`,
                hidden:!visibility[`${record.data_type}_${record.id}`]
              }
            ))
        ]
      };

      var _options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: true,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
          },
          title: {
            display: false,
          },
          annotation: {
            annotations: [
              ...targetEventData.map((e) => (
                {
                  type: 'box', // Draw a rectangle
                  xMin: e.start_time, // Start time of the e
                  xMax: e.end_time, // End time of the event
                  backgroundColor: COLORS[0],
                  borderColor: COLORS[0]
                  
                  // borderWidth: 2,
                }
              )),
              ...eventData.flatMap((events) => (
                events.values.map((e) => (
                  {
                    type: 'box', // Draw a rectangle
                    xMin: e.start_time, // Start time of the e
                    xMax: e.end_time, // End time of the event
                    backgroundColor: dataColors[`${e.data_type}_${e.id}`],
                    borderColor: dataColors[`${e.data_type}_${e.id}`]
                  }
                ))
              ))
            ]
          },
        },
        scales: {
          x: {
            type: 'time', // Time series on the X axis
            // time: {
            //     unit: 'hour',
            // },
            min:zoomStartDate.toISOString(),
            max:zoomEndDate.toISOString(),
          },
        },
        // onHover: (event, chartElement) => {
        //   const time = dayjs(event.chart.scales.x.getValueForPixel(event.x))
        //   var inBox = false;
        //   for(const event of eventData){
        //     const start = dayjs(event.start_time);
        //     const end = dayjs(event.end_time);
        //     if((time >= start) && (time <= end)){
        //       setHoverEvent(event);
        //       inBox = true;
        //       break;
        //     }
        //   }
        // },
      };

      sensorData.forEach((data, idx) => {
        _options.scales[`y${idx + 1}`] = {
          type: 'linear',
          display: visibility[data.title],
          position: 'left',
          border: {
              color: dataColors[`${data.data_type}_${data.id}`]
          },
        }
      })

      recordData.forEach((data, idx) => {
        _options.scales[`y${sensorData.length + idx + 1}`] = {
          type: 'linear',
          display: visibility[data.title],
          position: 'left',
          border: {
              color: dataColors[`${data.data_type}_${data.id}`]
          },
        }
      })
        console.log(_chartData);
        setChartData({..._chartData});
        setOptions({..._options});
    }

  }, [predictionData, target, dataColors, eventData, startDate, endDate, zoomStartDate, zoomEndDate])

  return (
    <div style={{height:'620px',width:'100%'}}>
      {
        (chartData && options) &&
        <Line data={chartData} options={options} />
      }
    </div>
  );
};

export default PredictiveTSGraph;
