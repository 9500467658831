import { useEffect, useState } from 'react';

import { Box, IconButton } from "@mui/material";
import { Table, TableCell, TableHead, Typography, TableBody, TableRow } from "@mui/material";

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ThermostatIcon from "@mui/icons-material/Thermostat";
import SensorsIcon from "@mui/icons-material/Sensors"
import ArticleIcon from '@mui/icons-material/Article';
import SparkLine from "./sparkline";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import EventsTSGraph from './events-graph';
import EventIcon from '@mui/icons-material/Event';

import dayjs from "dayjs";
import PDFViewer from './pdf-viewer';

import { GROUP_COLORS } from '../../constants';

export default function ManualsTable({monitor, blobUrl, fliename}) {
    const [manuals, setManuals] = useState([]);

    useEffect(() => {
        const _manual = [{
            'name': blobUrl,
            'date_uploaded': monitor.creation_time,
        }]
        setManuals(_manual);
    }, [monitor, blobUrl, fliename])

    const [selectedManual, setSelectedManual] = useState(null);

    const HeaderCell = ({column, description}) => {
        return (
            <TableCell>
                <Typography
                    sx={{
                        fontWeight:'600',
                        fontSize:'11px'
                    }}
                >
                    {column}
                    {
                        description &&
                        <span style={{fontWeight:500}}> ({description})</span>
                    }
                </Typography>
            </TableCell>
        );
    }

    const ManualCell = ({manual}) => {
        console.log(manual);
        return (
            <TableCell
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'8px',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'blue',
                            borderRadius:'5px',
                            pr:'2px',
                            pl:'2px',
                            // height:'30px',
                            // width:'30px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center'
                        }}
                    >
                        <ArticleIcon sx={{fill:'#FFF'}} />
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'5px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize:'14px',
                                fontWeight:'550'
                            }}
                        >{monitor.document_filename}</Typography>
                    </Box>
                </Box>
            </TableCell>
        )
    }

    const DateUploadedCell = ({manual}) => {
        return (
            <TableCell
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'8px',
                    }}
                >
                    <Typography sx={{fontSize:'14px', fontWeight:'550'}}>
                        {monitor.creation_time}
                    </Typography>
                </Box>
            </TableCell>
        );
    }

    const FilenameCell = ({manual}) => {
        return (
            <TableCell
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'8px',
                    }}
                >
                    <Typography sx={{fontSize:'14px', fontWeight:'550'}}>
                        {manual.file_name}
                    </Typography>
                </Box>
            </TableCell>
        );
    }

    const handleManualSelect = (manual) => {
        if(selectedManual && (manual.name === selectedManual.name)){
            setSelectedManual(null);
        }else{
            setSelectedManual(manual);
        }
    }


    const ManualRow = ({manual}) => {
        var selected = false;
        if(selectedManual && setSelectedManual.name === manual.name){
            selected = true;
        }
        console.log(manual);
        return (
            <TableRow 
                sx={{
                    backgroundColor: selected ? 'rgba(114, 110, 255, 0.08)' : 'white',
                    borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                }}
                onClick={() => handleManualSelect(manual)}
            >
                <ManualCell manual={manual}/>
                <DateUploadedCell manual={manual}/>
            </TableRow>
        );
    }

    const startTime = dayjs('2024-06-07 14:00:00').toISOString();
    const endTime = dayjs('2024-06-07 20:00:00').toISOString();

    const ManualViewer = ({manual}) => {
        return (
            <TableRow
                sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                }}
            >
                <TableCell
                    colSpan={5}
                >
                    <PDFViewer blobUrl={blobUrl}/>
                </TableCell>
            </TableRow>
        );
    }

    const [rows, setRows] = useState([]);
    useEffect(() => {
        if(manuals.length > 0){
            var _rows = [<ManualRow manual={manuals[0]}/>]
            if(selectedManual){
                _rows.push(<ManualViewer manual={manuals[0]}/>)
            }
            setRows(_rows);
        }
    }, [selectedManual, manuals])

    return (
        <Box
            sx={{
                borderTopLeftRadius:'10px',
                borderTopRightRadius:'10px',
                overflow:'hidden',
                border: '1px solid rgba(0, 0, 0, 0.15)'
            }}
        >
            <Table>
                <TableHead
                    sx={{
                        backgroundColor:'#F7F7F7',
                        fontWeight:'500',
                        fontSize:'11px',
                    }}
                >
                    <HeaderCell column={'Filename'}/>
                    <HeaderCell column={'Date Uploaded'}/>
                </TableHead>
                <TableBody>
                    {
                        rows.map((row) => (row))
                    }
                </TableBody>
            </Table>
        </Box>
    );
}