import { Box, Typography, Divider } from "@mui/material";
import VariableCard from "./variable-card";

export default function TargetSection({target, dataColors, model}){
    return (
        <Box
            sx={{
            borderBottom: "1px solid lightgrey",
            backgroundColor: "rgba(247, 247, 247, 1)",
            }}
        >
            <Box
            sx={{
                backgroundColor: "white",
                height: "48px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
                gap: "20px",
            }}
            >
            <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                Target
            </Typography>
            </Box>
            <Divider />
            {
                target.map((t) => (
                    <VariableCard
                        dataColors={dataColors}
                        config={t}
                        idx={0}
                    />
                ))
            }
            {
                (model && (model.model_objective === 'Predictive')) &&
                <Box
                    sx={{
                        display:'flex'
                    }}
                >
                    <Box
                        sx={{
                            padding:'5px',
                            paddingLeft:'10px',
                            paddingRight:'10px',
                            borderRadius:'10px',
                            margin:'2px',
                            border:'1px solid lightgrey',
                            backgroundColor:'rgba(255,255,255,0.9)'
                        }}
                    >
                        <Typography fontSize='12px'>Prediction Window</Typography>
                        {model.prediction_window} {model.prediction_window_units}
                    </Box>
                    <Box
                        sx={{
                            padding:'5px',
                            paddingLeft:'10px',
                            paddingRight:'10px',
                            borderRadius:'10px',
                            margin:'2px',
                            border:'1px solid lightgrey',
                            backgroundColor:'rgba(255,255,255,0.9)'
                        }}
                    >
                        <Typography fontSize='12px'>Context Window</Typography>
                        {model.context_window} {model.context_window_units}
                    </Box>
                    <Box
                        sx={{
                            padding:'5px',
                            paddingLeft:'10px',
                            paddingRight:'10px',
                            borderRadius:'10px',
                            margin:'2px',
                            border:'1px solid lightgrey',
                            backgroundColor:'rgba(255,255,255,0.9)'
                        }}
                    >
                        <Typography fontSize='12px'>Sampling Frequency</Typography>
                        {model.sampling_frequency} {model.sampling_frequency_units}
                    </Box>
                </Box>
            }
        </Box>
    );
}