import { getRequest, postRequest } from "./requests";

export const API_URL = 'https://api.praxisapp.ai';
// export const API_URL = 'https://localhost:8000';

export async function getHistorians() {
    const result = await getRequest(API_URL + "/api/historian_service/getAll");
    return result['historians'];
};

export async function getConnections() {
  const result = await getRequest(API_URL + "/api/connection_service/getAll");
  return result['connections'];
};

export async function getEvents() {
  const result = await getRequest(API_URL + "/api/event_service/getAll");
  return result['events'];
};

export async function getEvent(eventId) {
  const result = await getRequest(API_URL + "/api/event_service/get?eventId=" + eventId);
  return result['event'];
};

export async function getEventData(eventId, startDate, endDate) {
  const result = await getRequest(
    API_URL + "/api/event_service/getData?eventId=" + eventId
    + "&startDate=" + startDate.toISOString() + '&endDate=' + endDate.toISOString()
  );
  console.log(result);
  return result
};

export async function getSensors() {
  const result = await getRequest(API_URL + "/api/sensor_service/getAll");
  return result['sensors'];
};

export async function getSensorData(
  sensor,
  startDate,
  endDate,
) {
  const result = await getRequest( API_URL + "/api/sensor_service/getTimeData/" +
  sensor.sensor_id + '?startDate=' + startDate.toISOString() + '&endDate=' + endDate.toISOString());
  return {
    header: result["header"],
    data: result["data"],
    latest_reading: result["data"][0]["time"],
    latest_value: result["data"][0]["value"],
  }
};

export async function getModels(){
  const result = await getRequest(API_URL + "/api/model_service/model/getAll");
  return result['models'];
};

export async function getModelResultsStatus(
  model
) {
  const result = await getRequest(API_URL + "/api/model_service/model/hasResults?modelId=" +
  model.model_id + '&version=' + model.version);
  return result["has_results"];
};

export async function getModelResults(
  model
) {
  const result = await getRequest(API_URL + "/api/model_service/model/getResults?modelId=" +
  model.model_id + '&version=' + model.version);
  return result["model_results"];
};

export async function createModel(
  name,
  modelType,
  objective,
  desc,
){
  const result = await getRequest(
    API_URL + "/api/model_service/model/create?name=" +
      name.toString() +
      "&modelType=" +
      modelType.toString() +
      "&modelObjective=" +
      objective.toString() +
      "&description=" +
      desc
  );
  return {
    success: result["success"],
    model_id: result["model_id"],
  }
};

export async function createTrainingDataset(
  name
){
  const result = await getRequest(
    API_URL + "/api/model_service/training_data/create?name=" +
      name.toString() +
      "&sensorConfig=" +
      JSON.stringify([])
  );

  return result["success"]
};

export async function getModel(
  modelId
){
  const result = await getRequest(API_URL + '/api/model_service/model/get?modelId=' + modelId);
  return result['model'];
};

export async function getTrainingDataset(
  trainingDatasetId
){
  const result = await getRequest(API_URL + '/api/model_service/training_data/get?trainingDatasetId=' + trainingDatasetId.toString());
  return result['training_set'];
};

export async function getSensor(
  sensorId
){
  const result = await getRequest(API_URL + '/api/sensor_service/get/' + sensorId.toString());
  console.log(result);
  return result['sensor'];
};

export async function saveTrainingDataset(trainingSet){
  const formData = new FormData();
  formData.append('sensorConfig', JSON.stringify(trainingSet['sensor_config']))
  formData.append('trainingSetId', trainingSet['training_set_id'])
  formData.append('name', trainingSet['name'])
  const result = await postRequest(API_URL + '/api/model_service/training_data/save', formData);
  return result['success'];
};

export async function saveGeneralizedTrainingDataset(modelId, importedSensors, importedStreams){
  const result = await getRequest(
    API_URL + '/api/model_service/generalized/saveInputs?streams=' + JSON.stringify(importedStreams)
    + '&sensors=' + JSON.stringify(importedSensors) + '&modelId=' + modelId
  );
  return result['success'];
}

export async function getSensorConfigGraphData(sensorConfig, visibility, startDate, endDate, samplingFrequency, samplingFrequencyUnits){
  const formData = new FormData();
  formData.append('sensorConfig', JSON.stringify(sensorConfig));
  formData.append('startDate', startDate.toISOString());
  formData.append('endDate', endDate.toISOString());
  formData.append('visibility', JSON.stringify(visibility));

  if(samplingFrequency && samplingFrequencyUnits){
    formData.append('samplingFrequency', samplingFrequency);
    formData.append('samplingFrequencyUnits', samplingFrequencyUnits);
  }
  
  const url = API_URL + '/api/graph_service/config/graph_data';
  const result = await postRequest(url, formData);

  return {
    data:result['data'],
  };
};

export async function saveModel(modelId, model, modelVersion, startDate, endDate){
  const result = await getRequest(API_URL + '/api/model_service/model/save?name='
  + model['name'].toString() + '&startTime=' + startDate.toISOString() +
  '&endTime=' + endDate.toISOString() + '&trainingDataset=' + model['training_set_id'] +
  '&contextWindow=' + (model['context_window']) + '&contextWindowUnits=' + (model['context_window_units']) +
  '&predictionWindow=' + (model['prediction_window']) + '&predictionWindowUnits=' + (model['prediction_window_units']) +
  '&samplingFrequency=' + (model['sampling_frequency']) + '&samplingFrequencyUnits=' + (model['sampling_frequency_units']) +
  '&sensitivity=' + (model['sensitivity']) + '&modelId=' + modelId.toString() + '&version=' + modelVersion);
  return result['success'];
};

export async function previewModel(modelId, model, startDate, endDate){
  const result = await getRequest(API_URL + '/api/model_service/model/preview' +
    '?trainingDataset=' + modelId +
    '&startTime=' + startDate.toISOString() +
    '&endTime=' + endDate.toISOString() +
    '&predIntValue=' + (model['interpolation_frequency'] ?? 1) +
    '&predIntType=' + (model['interpolation_frequency_units'] ?? 'minute') +
    '&lookbackCount=' + (model['lookback_count'] ?? 1)
  );

  return result
};

export async function trainModel(modelId){
    const result = await getRequest(
      API_URL + "/api/model_service/train?modelId=" +
        modelId
    );

    return result;
}

export async function getModelResources(model){
    var streams = [];
    var sensors = [];
    if (model.model_type == "Targeted") {
      const result = await getRequest(
        API_URL + "/api/model_service/training_data/get?trainingDatasetId=" +
          model.model_id
      );  
      sensors = result["training_set"]["sensor_config"];
    } else if (model.model_type == "Generalized") {
      sensors = model.sensors;
      streams = model.streams;
    }
    return {
      sensors: sensors,
      streams: streams,
    };
}

export async function getModelPredictions(model, sensor_id, startDate, endDate, forecastIndex){
  const result = await getRequest(
    API_URL + "/api/model_service/model/generalized/getTestPredictions?modelId=" +
      model.model_id +
      "&sensorId=" +
      sensor_id +
      "&enableOneShot=" +
      "true" +
      "&startDate=" +
      startDate.toISOString() +
      "&endDate=" +
      endDate.toISOString() +
      "&forecastIndex=" +
      forecastIndex
  );
  return result;
};

export async function createModelDeployment(model){
  const result = await getRequest(
    API_URL + "/api/model_service/model/createDeployment?modelId=" + model.model_id
  );
  return result
};

export async function getPredictiveModelPredictions(model, startDate, endDate){
  const result = await getRequest(
    API_URL + "/api/model_service/model/getPredictiveTestPredictions?modelId=" +
    model.model_id + "&startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString()
  );
  return result
};

export async function getPredictiveModelFeatureImportance(model){
  const result = await getRequest(
    API_URL + "/api/model_service/model/getPredictiveFeatureImportance?modelId=" + model.model_id
  );
  return result
};

export async function getPredictiveFeatureMetricRCA(model){
  const result = await getRequest(
    API_URL + "/api/model_service/model/getPredictiveFeatureMetricRCA?modelId=" + model.model_id
  );
  return result
};

export async function getPredictiveModelDeployment(model, startDate, endDate){
  const result = await getRequest(
    API_URL + "/api/model_service/model/getPredictiveDeploymentPredictions?modelId=" +
    model.model_id + "&startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString()
  );
  return result
};

export async function getAnomalyModelPredictions(model, startDate, endDate){
  const result = await getRequest(
    API_URL + "/api/model_service/model/getAnomalyTestPredictions?modelId=" +
    model.model_id + "&startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString()
  );
  return result
};

export async function getAnomalyModelDeployment(model, startDate, endDate){
  const result = await getRequest(
    API_URL + "/api/model_service/model/getAnomalyDeploymentPredictions?modelId=" +
    model.model_id + "&startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString()
  );
  return result
};

export async function getDowntimeRCA(startDate, endDate){
  const result = await getRequest(
    API_URL + "/api/model_service/objective/getPredictionRCA?startDate=" + startDate.toISOString()
    + "&endDate=" + endDate.toISOString()
  );
  return result
}

export async function getMonitorChatResponse(query){
  const result = await getRequest(
    API_URL + "/api/monitor_service/chatResponse?query=" + query
  );
  return result
}

export async function getRcaData(sensorId){
  const result = await getRequest(
    API_URL + "/api/model_service/model/getRcaData?sensorId=" + sensorId
  );
  return result
}

export async function getDowntimeModelPredictions(startDate, endDate){
  const result = await getRequest(
    API_URL + "/api/model_service/model/getDowntimeTestPredictions?startDate=" + startDate.toISOString()
    + "&endDate=" + endDate.toISOString()
  );
  return result
}

export async function getSensorGraphData(sensor, startDate, endDate){
  const result = await getRequest(API_URL + '/api/graph_service/graph_data_v2/' + sensor.sensor_id
    + '?startDate=' + startDate.toISOString() + '&endDate=' + endDate.toISOString()
  );
  return result
};

export async function getDemoAnomalies(
  modelId,
  detectionRange,
  detectionRangeUnits,
){
  const result = await getRequest(
    API_URL + "/api/model_service/objective/getVibrationAnomalies?modelId=" +
      modelId +
      "&detectionRange=" +
      detectionRange +
      "&detectionRangeUnits=" +
      detectionRangeUnits
  );
  return result;
};

export async function getAnomalies(
  modelId,
  detectionRange,
  detectionRangeUnits,
){
  const result = await getRequest(
    API_URL + "/api/model_service/objective/getAnomalyDetection?modelId=" +
      modelId +
      "&detectionRange=" +
      detectionRange +
      "&detectionRangeUnits=" +
      detectionRangeUnits
  );
  return result;
};

export async function getOptimizationCurrentValues(
  modelId,
  detectionRange,
  detectionRangeUnits,
){
  const result = await getRequest(
    API_URL + "/api/model_service/objective/getOptimizationCurrentValues?modelId=" +
      modelId +
      "&detectionRange=" +
      detectionRange +
      "&detectionRangeUnits=" +
      detectionRangeUnits +
      "&direction"
  );
  return result;
};

export async function getReccomendedOptimizations(
  modelId,
  optTargetValue,
  direction,
  actualsData,
){
  const result = await getRequest(
    API_URL + "/api/model_service/objective/getOptimizationReccomendedValues?modelId=" +
      modelId +
      "&targetValue=" +
      optTargetValue +
      "&direction=" +
      direction +
      "&magnitude=" +
      Math.abs(
        optTargetValue - parseFloat(actualsData[actualsData.length - 1])
      )
  );
  return result;
};

export async function getTargetedForecast(
  modelId
){
  const result = await getRequest(
    API_URL + "/api/model_service/objective/getForecastedValues?modelId=" +
      modelId
  );
  return result;
};

export async function getGeneralizedForecast(
  modelId,
  sensorId
){
  const result = await getRequest(
    API_URL + "/api/model_service/objective/getGeneralizedForecastedValues?modelId=" +
      modelId +
      "&sensorId=" +
      sensorId
  );
  return result;
};

export async function createMonitor(
  name,
  description,
  sensors,
  events,
  deployments,
  file
){
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  formData.append('description', description);
  formData.append('sensors', JSON.stringify(sensors));
  formData.append('events', JSON.stringify(events));
  formData.append('deployments', JSON.stringify(deployments));
  const result = await postRequest(
    API_URL + "/api/monitor_service/create",
    formData
  );
  return result
};

export async function getMonitorDocument(
  monitorId
){
  const token = localStorage.getItem('token'); // Retrieve the token from local storage
  const url = API_URL + "/api/monitor_service/getDocument?monitorId=" + monitorId
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
    },
  });

  if (response.ok) {
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    return url
  } else {
    console.error('Failed to fetch PDF:', response.status);
  }

}

export async function getMonitorData(monitorId, startDate, endDate) {
  const result = await getRequest(
    API_URL + "/api/monitor_service/getData?monitorId=" + monitorId
    + "&startDate=" + startDate.toISOString() + '&endDate=' + endDate.toISOString()
  );
  return result
};

export async function getMonitor(monitorId, startDate, endDate){
  const result = await getRequest(
    API_URL + "/api/monitor_service/get?monitorId=" + monitorId
  );
  return result
};


export async function getAllMonitors(){
  const result = await getRequest(
    API_URL + "/api/monitor_service/getAll"
  );
  return result
};

export async function getMonitorAnomalyData(monitorId, timeFrame, timeFrameUnits){
  const result = await getRequest(
    API_URL + "/api/monitor_service/getAnomalyData?monitorId=" + monitorId
    + "&timeFrame=" + timeFrame + "&timeFrameUnits=" + timeFrameUnits
  );

  return result;
};

export async function getMonitorsAnomalyData(monitorIds, timeFrame, timeFrameUnits){
  const result = await getRequest(
    API_URL + "/api/monitor_service/getMonitorsAnomalyData?monitorIds=" + monitorIds
    + "&timeFrame=" + timeFrame + "&timeFrameUnits=" + timeFrameUnits
  );

  return result;
};

export async function getMonitorVibrationAnomalies(timeFrame, timeFrameUnits){
  const result = await getRequest(
    API_URL + "/api/monitor_service/getVibrationAnomalyData?timeFrame=" + timeFrame
    + "&timeFrameUnits=" + timeFrameUnits
  );

  return result;
};

export async function getMonitorEvents(eventType, timeFrame, timeFrameUnits){
  const result = await getRequest(
    API_URL + "/api/monitor_service/getMonitorEvents?timeFrame=" + timeFrame
    + "&timeFrameUnits=" + timeFrameUnits + '&eventType=' + eventType
  );

  return result;
};

export async function getQuoteSimilarity(selectedModelFile){
  const result = await getRequest(
    API_URL + "/api/quotes/getSimilarity?selectedModelFile=" + selectedModelFile
  );

  return result;
};

export async function getQuoteSimilarityLogs(){
  const result = await getRequest(
    API_URL + "/api/quotes/getSimilarityLogs"
  );

  return result;
};

export async function getMaxeResponse(monitorId, query, conversation){
  const result = await getRequest(
    API_URL + "/api/monitor_service/maxe/response?monitorId=" + monitorId + '&query=' + query
    + '&conversation=' + JSON.stringify(conversation)
  )

  return result
}

export async function getMaxeInitStatus(monitorId){
  const result = await getRequest(
    API_URL + "/api/monitor_service/maxe/status?monitorId=" + monitorId 
  )

  return result
}

export async function initializeMaxe(monitorId, startDate, endDate){
  const result = await getRequest(
    API_URL + "/api/monitor_service/maxe/init?monitorId=" + monitorId 
    + "&startDate=" + startDate.toISOString() + '&endDate=' + endDate.toISOString()
  )

  return result
}
