import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Divider,
  Modal,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StormIcon from "@mui/icons-material/Storm";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';

import { useNavigate } from "react-router-dom";

import SensorSelectionModal from './sensor-selection-modal'
import FileUpload from "./file-upload";

import { createModel, createMonitor,  getTrainingDataset, createTrainingDataset, getSensors, getEvents, getModels } from "../../../../api";

export default function CreateModal({ open, handleModalState }) {
  const navigate = useNavigate();

  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const now = new Date();
  const formattedDate = now
    .toLocaleDateString("en-US", options)
    .replace(",", "")
    .split(",")[0];
  const time = now
    .toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replace(" ", "");
  const [name, setName] = React.useState(
    `New Monitor - ${formattedDate} at ${time}`
  );
  const handleNameChange = (newName) => {
    setName(newName);
  };

  const [desc, setDesc] = React.useState(null);
  const handleDescChange = (newDesc) => {
    setDesc(newDesc);
  };

  const [file, setFile] = useState(null);

  const [sensorList, setSensorList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [deploymentList, setDeploymentList] = useState([]);
  useEffect(() => {
      const _getSensors = async () => {
        const sensors = await getSensors();
        setSensorList([...sensors]);
      };
      _getSensors();
  }, [])

  useEffect(() => {
      const _getEvents = async () => {
          const events = await getEvents();
          setEventsList([...events]);
      }
      _getEvents();
  }, [])

  useEffect(() => {
      const _getModels = async () => {
          var deployments = [];
          const models = await getModels();
          models.forEach((model) => {
              if(model.is_deployed === 'true'){
                  model['deployment_id'] = model['model_id'];
                  deployments.push(model);
              }
          })
          setDeploymentList(deployments);
      }
      _getModels();
  }, [])

  const [anchor, setAnchor] = useState(null);
  const handleImportModal = (event) => {
      setAnchor(anchor ? null : event.currentTarget)
  }

  const getSensorMetadata = (sensor, dataType) => {
    const sensorImportData = {
        ...sensor,
        'data_type': dataType,
        'variable_type': null,
        'interpolation_strategy': null,
        'null_strategy': null,
        'fill_value': null,
        'transforms': [],
        'sensor_type': null,
    }
    return sensorImportData
};

const [selectedSensors, setSelectedSensors] = useState([]);
const [selectedEvents, setSelectedEvents] = useState([]);
const [selectedDeployments, setSelectedDeployments] = useState([]);
const [reccomendedSensors, setReccomendedSensors] = useState([]);
const [reccomendedEvents, setReccomendedEvents] = useState([]);

const handleSensorSelection = async (sensors, dataType) => {
    var _selectedSensors = [...selectedSensors];
    var _selectedEvents = [...selectedEvents];
    var _selectedDeployments = [...selectedDeployments];
    sensors.forEach((sensor) => {
        var sensorImport = getSensorMetadata(sensor, dataType);
        const idCol = `${dataType}_id`;
        const key = `${dataType}_${sensorImport[idCol]}`;
        sensorImport['key'] = key;
        if(dataType === 'sensor'){
          _selectedSensors.push(sensorImport);
        }else if(dataType === 'event'){
          _selectedEvents.push(sensorImport);
        }else{
          _selectedDeployments.push(sensorImport);
        }
    })
    setSelectedSensors(_selectedSensors);
    setSelectedEvents(_selectedEvents);
    setSelectedDeployments(_selectedDeployments);
}

useEffect(() => {
  var _reccomendedSensors = [];
  var _reccomendedEvents = [];
  const getDeploymentResources = async () => {
    var deploymentId = selectedDeployments[selectedDeployments.length - 1]['deployment_id'];
    const result = await getTrainingDataset(deploymentId);
    result.sensor_config.forEach((resource) => {
      if(resource.data_type === 'sensor'){
        if(!selectedSensors.map((s) => (s.sensor_id)).includes(resource.sensor_id)){
          _reccomendedSensors.push(resource);
        }
      }else if(resource.data_type === 'event'){
        if(!selectedEvents.map((e) => (e.event_id)).includes(resource.event_id)){
          _reccomendedEvents.push(resource);
        }
      }
    })
    setReccomendedSensors(_reccomendedSensors);
    setReccomendedEvents(_reccomendedEvents);
  }
  if(selectedDeployments.length > 0){
    getDeploymentResources();
  }
}, [selectedDeployments])

const handleSensorDelete = async (resource, dataType) => {
  var _selectedSensors = [];
  var _selectedEvents = [];
  var _selectedDeployments = [];
  if(dataType === 'sensor'){
    selectedSensors.forEach((sensor) => {
      if(sensor.sensor_id != resource.sensor_id){
        _selectedSensors.push(sensor);
      }
    })
  }else if(dataType === 'event'){
    selectedEvents.forEach((sensor) => {
      if(sensor.sensor_id != resource.sensor_id){
        _selectedEvents.push(sensor);
      }
    })
  }else{
    selectedDeployments.forEach((sensor) => {
      if(sensor.sensor_id != resource.sensor_id){
        _selectedDeployments.push(sensor);
      }
    })
  }
  setSelectedSensors(_selectedSensors);
  setSelectedEvents(_selectedEvents);
  setSelectedDeployments(_selectedDeployments);
}

useEffect(() => {
  var _sensorList = [];
  var _eventsList = [];
  var _deploymentsList = [];
  sensorList.forEach((sensor) => {
    if(!selectedSensors.map((s) => (s.sensor_id)).includes(sensor.sensor_id)){
      _sensorList.push(sensor);
    }
  })
  eventsList.forEach((event) => {
    if(!selectedEvents.map((e) => (e.event_id)).includes(event.event_id)){
      _eventsList.push(event);
    }
  })
  deploymentList.forEach((deployment) => {
    if(!selectedDeployments.map((d) => (d.deployment_id)).includes(deployment.deployment_id)){
      _deploymentsList.push(deployment);
    }
  })
  setSensorList(_sensorList);
  setEventsList(_eventsList);
  setDeploymentList(_deploymentsList);
}, [selectedSensors, selectedEvents, selectedDeployments])

const SensorTag = ({sensor}) => {
  return (
    <Box
      sx={{
        backgroundColor:'rgb(40,40,40)',
        color:'white',
        padding:'5px',
        pl:'10px',
        pr:'10px',
        borderRadius:'8px',
        fontSize:'10px',
        color:'white',
        display:'flex',
        alignItems:'center',
        gap:'4px',
        cursor:'pointer',
      }}
    >
      <IconButton onClick={() => handleSensorDelete(sensor, 'sensor')} sx={{m:0, p:0}}>
        <CancelIcon sx={{fill:'white'}}/>
      </IconButton>
      <Typography>
        {sensor.name} ({sensor.tag_id})
      </Typography>
    </Box>
  )
}

const EventTag = ({event}) => {
  return (
    <Box
      sx={{
        backgroundColor:'rgb(40,40,40)',
        color:'white',
        padding:'5px',
        pl:'10px',
        pr:'10px',
        borderRadius:'8px',
        fontSize:'10px',
        color:'white',
        display:'flex',
        alignItems:'center',
        gap:'4px',
        cursor:'pointer',
      }}
    >
      <IconButton onClick={() => handleSensorDelete(event, 'event')} sx={{m:0, p:0}}>
        <CancelIcon sx={{fill:'white'}}/>
      </IconButton>
      <Typography>
        {event.name}
      </Typography>
    </Box>
  )
}

const DeploymentTag = ({deployment}) => {
  return (
    <Box
      sx={{
        backgroundColor:'rgb(40,40,40)',
        color:'white',
        padding:'5px',
        pl:'10px',
        pr:'10px',
        borderRadius:'8px',
        fontSize:'10px',
        color:'white',
        display:'flex',
        alignItems:'center',
        gap:'4px',
        cursor:'pointer',
      }}
    >
      <IconButton onClick={() => handleSensorDelete(deployment, 'deployment')} sx={{m:0, p:0}}>
        <CancelIcon sx={{fill:'white'}}/>
      </IconButton>
      <Typography>
        {deployment.name}
      </Typography>
    </Box>
  )
}

const handleCreateMonitor = async () => {
  const result = await createMonitor(name, desc, selectedSensors, selectedEvents, selectedDeployments, file);
  if(result['success']){
    navigate('/monitors/monitor-explorer/' + result['monitor_id'])
  }
}

const handleImportReccomendedSensors = () => {
  if(reccomendedSensors.length > 0){
    handleSensorSelection(reccomendedSensors, 'sensor')
    setReccomendedSensors([]);
  }
}

const handleImportReccomendedEvents = () => {
  if(reccomendedEvents.length > 0){
    handleSensorSelection(reccomendedEvents, 'event')
    setReccomendedEvents([]);
  }
}


  return (
    <Modal
      open={open}
      onClose={() => handleModalState(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "800px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 24,
          maxHeight:'90vh',
          overflow:'scroll'
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingBottom: "15px",
                paddingTop: "15px",
              }}
            >
              <Typography fontWeight="bold">Create monitor</Typography>
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                }}
              >
                <CancelIcon onClick={() => handleModalState(false)} />
              </IconButton>
            </Box>
            <Divider />
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "15px",
                paddingTop: "20px",
                display: "flex",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <Typography color="rgba(79, 79, 79, 1)">
                Name<span style={{ color: "rgba(255,20,20)" }}>*</span>
              </Typography>
              <TextField
                autoFocus
                onFocus={(event) => {
                  event.target.select();
                }}
                onChange={(e) => handleNameChange(e.target.value)}
                value={name}
                placeholder="Enter name..."
                size="small"
                sx={{ width: "100%" }}
                variant="outlined"
              />
            </Box>
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "15px",
                paddingTop: "5px",
                display: "flex",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <Typography color="rgba(79, 79, 79, 1)">
                Description<span style={{ color: "rgba(255,20,20)" }}>*</span>
              </Typography>
              <TextField
                onChange={(e) => handleDescChange(e.target.value)}
                value={desc}
                placeholder="Enter description..."
                size="small"
                sx={{ width: "100%" }}
                variant="outlined"
              />
            </Box>
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "15px",
                paddingTop: "5px",
                display: "flex",
                gap: "5px",
                flexDirection: "column",
              }}
              onClick={handleImportModal}
            >
              <Typography color="rgba(79, 79, 79, 1)">
                Resources
              </Typography>
              <SensorSelectionModal handleSensorSelection={handleSensorSelection} deploymentList={deploymentList} eventsList={eventsList} sensorList={sensorList} handleImportModal={handleImportModal} />
            </Box>
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "15px",
                paddingTop: "5px",
                display: "flex",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display:'flex',
                  alignItems:'center',
                  gap:'20px'
                }}
              >
                <Typography color="rgba(79, 79, 79, 1)">
                  Sensors
                </Typography>
                {
                  (reccomendedSensors.length > 0) &&
                  <Button onClick={handleImportReccomendedSensors} size='small'>
                    Import {reccomendedSensors.length} Reccomended Sensor(s)
                  </Button>
                }
              </Box>
              <Box
                sx={{
                  display:'flex',
                  gap:'10px',
                  overflow:'wrap',
                  minHeight:'10px'
                }}
              >
                {
                  selectedSensors.map((sensor) => (
                    <SensorTag sensor={sensor}/>
                  ))
                }
              </Box>
            </Box>
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "15px",
                paddingTop: "5px",
                display: "flex",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display:'flex',
                  alignItems:'center'
                }}
              >
                <Typography color="rgba(79, 79, 79, 1)">
                  Events
                </Typography>
                {
                  (reccomendedEvents.length > 0) &&
                  <Button onClick={handleImportReccomendedEvents} size='small'>
                    Import {reccomendedEvents.length} Reccomended Event(s)
                  </Button>
                }
              </Box>
              <Box
                sx={{
                  display:'flex',
                  gap:'10px',
                  overflow:'wrap',
                  minHeight:'10px'
                }}
              >
                {
                  selectedEvents.map((event) => (
                    <EventTag event={event}/>
                  ))
                }
              </Box>
            </Box>
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "15px",
                paddingTop: "5px",
                display: "flex",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <Typography color="rgba(79, 79, 79, 1)">
                Deployments
              </Typography>
              <Box
                sx={{
                  display:'flex',
                  gap:'10px',
                  overflow:'wrap',
                  minHeight:'10px'
                }}
              >
                {
                  selectedDeployments.map((deployment) => (
                    <DeploymentTag deployment={deployment}/>
                  ))
                }
              </Box>
            </Box>
            <Box
              sx={{
                padding: "24px",
                paddingBottom: "15px",
                paddingTop: "5px",
                display: "flex",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <Typography color="rgba(79, 79, 79, 1)">
                Files
              </Typography>
              <FileUpload file={file} setFile={setFile}/>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            gap: "5px",
            padding: "20px 24px",
          }}
        >
          <Button
            sx={{
              textTransform: "none",
              borderColor: "rgba(55, 70, 91, 0.32)",
              color: "black",
              width: "50px",
              "&:hover": {
                borderColor: "rgba(55, 70, 91, 0.32)",
                backgroundColor: "rgba(55, 70, 91, 0.05)", // Slight background change on hover
              },
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "rgba(33, 150, 83, 1)",
              color: "white",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "rgba(33, 150, 83, 0.95)", // Hover background color
              },
            }}
            variant="contained"
            onClick={handleCreateMonitor}
          >
            Create monitor
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
