import ReactDOM from "react-dom/client";
import * as React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css'

import HomePage from './applications/home-page/home';

import MLFactoryHome from "./applications/ml-factory/home";
import MLFactoryModelOverview from "./applications/ml-factory/model-overview";
import ModelTesting from "./applications/ml-factory/model-testing";
import ModelTestingV2 from "./applications/ml-factory/model-testing-v2";
import ModelSandbox from "./applications/ml-factory/model-sandbox";
import ModelSandboxV2 from "./applications/ml-factory/model-sandbox-v2";
import ModelSandboxV3 from "./applications/ml-factory/model-sandbox-v3";
import ModelBuilder from "./applications/ml-factory/model-builder"
import ModelDeploymentDashboard from "./applications/ml-factory/model-deployment";

import SensorHubHome from "./applications/sensor-hub/home";

import MonitorsHome from "./applications/monitors/home"
import MonitorExplorer from "./applications/monitors/explorer";

import useToken from "./authorization/use-token";
import LoginPage from "./applications/login";
import AuthCheck from "./authorization/auth-check";
import QuotingTool from "./applications/quoting-tool";
import EventHubHome from "./applications/event-hub/home";



export default function App() {
    const { token, removeToken, setToken } = useToken();

    return (
        <BrowserRouter>
        <Routes>
            <Route 
                exact
                path="/login" 
                element={<LoginPage setToken={setToken} />} 
            />
            <Route
                exact
                path="/"
                element={
                <AuthCheck>
                    <HomePage />
                </AuthCheck>
                }
            ></Route>
            <Route
                exact
                path="/sensor-hub"
                element={
                <AuthCheck>
                    <SensorHubHome />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/event-hub"
                element={
                <AuthCheck>
                    <EventHubHome />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/ml-factory"
                element={
                <AuthCheck>
                    <MLFactoryHome />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/ml-factory/model-overview/:modelId"
                element={
                <AuthCheck>
                    <MLFactoryModelOverview />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/ml-factory/model-sandbox-v0/:modelId"
                element={
                <AuthCheck>
                    <ModelSandboxV2 />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/ml-factory/model-testing-v0/:modelId"
                element={
                <AuthCheck>
                    <ModelTesting />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/ml-factory/model-deployment/:modelId"
                element={
                <AuthCheck>
                    <ModelDeploymentDashboard />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/ml-factory/model-testing/:modelId"
                element={
                <AuthCheck>
                    <ModelTestingV2 />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/ml-factory/model-sandbox/:modelId"
                element={
                <AuthCheck>
                    <ModelSandboxV3 />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/ml-factory/model-builder/:modelId"
                element={
                <AuthCheck>
                    <ModelBuilder />
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/monitors"
                element={
                <AuthCheck>
                    <MonitorsHome/>
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/monitors/monitor-explorer/:monitorId"
                element={
                <AuthCheck>
                    <MonitorExplorer/>
                </AuthCheck>
            }
            ></Route>
            <Route
                exact
                path="/quotes/create-quote"
                element={
                <AuthCheck>
                    <QuotingTool/>
                </AuthCheck>
            }
            ></Route>
        </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);