import * as React from "react";
import dayjs from 'dayjs';

import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from '@mui/icons-material/Event';
import ShowChartIcon from "@mui/icons-material/ShowChart";
import StorageIcon from "@mui/icons-material/Storage";
import TableChartIcon from "@mui/icons-material/TableChart";
import {
  Button, Divider, Grid, TextField, Typography, ListItemButton
} from "@mui/material";
import Box from "@mui/material/Box";

import { getConnections, getEvents, getEventData } from "../../../api";
import CustomCheckBox from "./components/custom-check-box";
import DatePickerComponent from "./components/date-picker";
// import FilterBox from "./components/filter-box";
import ConnectionCard from "./components/connection-card";
import EventsTable from "./components/events-table";
import TSDataTable from "./components/ts-data-table";
import { useFilterList } from "./helpers";
import EventsTSGraph from "./components/events-graph";
import RecordsTSGraph from "./components/records-graph";

export default function EventHubHome({}) {
  const [connectionList, setConnectionList] = React.useState([]);
  const [eventList, setEventList] = React.useState([]);

  const [selectedConnection, setSelectedConnection] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = React.useState({
    name: "",
    description: "",
    sensor_id: "",
  });

  const [dataTab, setDataTab] = React.useState("Chart");
  const [tab, setTab] = React.useState("Models");

  const [loading, setLoading] = React.useState(true);
  const [header, setHeader] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [reading, setReading] = React.useState("");
  const [eventCount, setEventCount] = React.useState("");
  const [startDate, setStartDate] = React.useState(dayjs('2024-06-02'));
  const [endDate, setEndDate] = React.useState(dayjs('2024-06-09'));
  

  const [searchQuery, setSearchQuery] = React.useState(null);
  const [filteredEvents, setFilteredEvents] = React.useState([]);

  React.useEffect(() => {
    const _getConnections = async () => {
      const connections = await getConnections();
      setConnectionList([...connections]);
    }
    _getConnections();
  }, []);

  React.useEffect(() => {
    const _getEvents = async () => {
      const events = await getEvents();
      setEventList([...events]);
    };
    _getEvents();
  }, []);

  React.useEffect(() => {
    const _getEventData = async () => {
      const { header, events_list, total_events, last_event_time, graph_type } = await getEventData(selectedEvent.event_id, startDate, endDate);
      setHeader(header);
      setData(events_list);
      setEventCount(total_events)
      setReading(last_event_time);
    }
    setLoading(true);
    if(selectedEvent.name.length){
      _getEventData();
    }
    setLoading(false);
  }, [selectedEvent, startDate, endDate]);

  useFilterList(eventList, searchQuery, setFilteredEvents);
  return (
    <Box>
      <Box
        sx={{
          paddingTop: "24px",
          paddingLeft: "64px",
        }}
      >
        <Typography fontSize="1.8rem" variant="h4">
          Events Hub
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          paddingTop: "25px",
          paddingLeft: "64px",
          paddingRight: "64px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "40px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <StorageIcon />
            <Typography fontWeight="bold" fontSize="1.2rem" variant="h6">
              Connections
            </Typography>
          </Box>
          <Button
            sx={{
              color: "rgba(70, 67, 212, 1)",
              textTransform: "none",
              padding: 0,
              paddingTop: "1px",
              margin: 0,
            }}
            endIcon={<ArrowForwardIcon />}
          >
            <Typography fontSize="0.9rem">View all</Typography>
          </Button>
        </Box>
        <Button
          variant="outlined"
          sx={{
            color: "rgba(75, 64, 221, 1)",
            border: "1px solid rgba(114, 110, 255, 0.4)",
            textTransform: "none",
          }}
          startIcon={<AddIcon />}
        >
          Connect Data
        </Button>
      </Box>
      <Box
        sx={{
          paddingTop: "15px",
          paddingLeft: "64px",
          paddingRight: "64px",
        }}
      >
        <Grid container spacing={"40px"}>
          {connectionList.map((historian) => (
            <Grid item xs={4}>
              <ConnectionCard historian={historian} selectedHistorian={selectedEvent} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          paddingTop: "40px",
          paddingLeft: "64px",
          paddingRight: "64px",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <EventIcon />
          <Typography fontWeight="bold" fontSize="1.2rem" variant="h6">
            Events
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "2px",
            width: "60px",
            height: "30px",
            borderRadius: "20px",
            backgroundColor: "rgba(220, 220, 220, 1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography fontSize="0.9rem" fontWeight="bold">
            {
              filteredEvents.length
            }
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          height: "40px",
          paddingTop: "20px",
          paddingLeft: "64px",
          paddingRight: "64px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "50%",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "60%",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              gap: "10px",
              paddingLeft: "10px",
              boxShadow: 1,
              overflow:'hidden'
            }}
          >
            <SearchIcon fontSize="1.2rem" />
            <TextField
              onChange={(event) => setSearchQuery(event.target.value)}
              size='small'
              fullWidth={true}
              variant='filled'
              placeholder="Search..."
              autoComplete="off" // Disables browser autocomplete
            />
          </Box>
          <Box
            sx={{
              border: "1px solid lightgrey",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              boxShadow: 1,
              cursor:'pointer'
            }}
          >
            <Typography fontSize="0.9rem">Any Connection</Typography>
            <ArrowDropDownIcon fontSize="1.2rem" />
          </Box>
        </Box>
        <Button
          color="success"
          startIcon={<AddIcon />}
          endIcon={<ArrowDropDownIcon />}
          variant="contained"
        >
          Ingest
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginRight: "64px",
          marginLeft: "64px",
          marginTop: "10px",
          border: "1px solid rgba(0, 0, 0, 0.15)",
          marginBottom: "30px",
          backgroundColor: "white",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "55%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              display: "flex",
              gap: "20px",
              alignItems: "center",
              padding: "12px 16px",
            }}
          >
            <CustomCheckBox variant="all" />
            <Typography fontSize="14px" fontWeight="bold">
              Events
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              height:'110vh',
              overflow:'scroll'
            }}
          >
            <EventsTable filteredEvents={filteredEvents} selectEvent={setSelectedEvent} selectedEvent={selectedEvent}/>
          </Box>
        </Box>
        <Divider flexItem orientation="vertical" />
        <Box
          sx={{
            width: "45%",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px",
                  alignItems: "center",
                  marginTop: "3px",
                  height: "32px",
                  width: "32px",
                  borderRadius: "4px",
                  color: "white",
                  backgroundColor: 'black',
                }}
              >
                <EventIcon/>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  fontSize="1.2rem"
                >
                  {selectedEvent.name}
                </Typography>
                <Typography variant="body1" fontSize="0.9rem">
                  {selectedEvent.description}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "10px",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  borderColor: "black",
                  color: "black",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                  },
                }}
                variant="outlined"
              >
                <Typography fontWeight="bold">...</Typography>
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                width: "50%",
                padding: "20px",
                paddingBottom: "15px",
                paddingTop: "15px",
              }}
            >
              <Typography fontSize="0.9rem">Latest event</Typography>
              <Typography fontWeight="bold">
                {!loading ? reading : "..."}
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box
              sx={{
                width: "50%",
                padding: "20px",
                paddingBottom: "15px",
                paddingTop: "15px",
              }}
            >
              <Typography fontSize="0.9rem">Total count</Typography>
              <Typography fontWeight="bold">
                {!loading ? eventCount : "..."}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid rgba(0, 0, 0, 0.07)",
                display: "flex",
                gap: "5px",
                alignItems: "center",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <DatePickerComponent date={startDate} getDate={(date) => setStartDate(date)}/>
              <DatePickerComponent date={endDate} getDate={(date) => setEndDate(date)}/>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                sx={{
                  borderColor:
                    dataTab == "Chart"
                      ? "rgba(70, 67, 212, 1)"
                      : "rgba(0, 0, 0, 0.07)",
                  textTransform: "none",
                  color:
                    dataTab == "Chart" ? "rgba(70, 67, 212, 1)" : "black",
                  width: "80px",
                  "&:hover": {
                    borderColor:
                      dataTab == "Chart"
                        ? "rgba(70, 67, 212, 1)"
                        : "rgba(191, 190, 232, 1)",
                    backgroundColor:
                      dataTab == "Chart" ? "white" : "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                  },
                }}
                onClick={() => setDataTab("Chart")}
                variant="outlined"
                startIcon={<ShowChartIcon />}
              >
                Chart
              </Button>
              <Button
                sx={{
                  borderColor:
                    dataTab == "Data"
                      ? "rgba(70, 67, 212, 1)"
                      : "rgba(0, 0, 0, 0.07)",
                  textTransform: "none",
                  color: dataTab == "Data" ? "rgba(70, 67, 212, 1)" : "black",
                  width: "80px",
                  "&:hover": {
                    borderColor:
                      dataTab == "Data"
                        ? "rgba(70, 67, 212, 1)"
                        : "rgba(191, 190, 232, 1)",
                    backgroundColor:
                      dataTab == "Data" ? "white" : "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                  },
                }}
                onClick={() => setDataTab("Data")}
                variant="outlined"
                startIcon={<TableChartIcon />}
              >
                Data
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              // alignItems: "center",
              padding: "10px",
              minHeight: "400px",
            }}
          >
            {((selectedEvent.event_id !== "") && (selectedEvent.event_type == 'event') && (dataTab == "Chart") && (data.length)) &&
              <EventsTSGraph
                events={data}
                startTime={startDate}
                endTime={endDate}
              />
            }
            {((selectedEvent.event_id !== "") && (selectedEvent.event_type == 'anomaly') && (dataTab == "Chart") && (data.length)) &&
              <RecordsTSGraph
                events={data}
                startTime={startDate}
                endTime={endDate}
              />
            }
            {selectedEvent.sensor_id !== "" && dataTab == "Data" && (
              <TSDataTable loading={loading} header={header} data={data} />
            )}
          </Box>
          <Divider />
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "20px",
              paddingRight: "20px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                padding="10px 8px 10px 0px"
                variant="body1"
                fontWeight="bold"
              >
                Usage
              </Typography>
              <Box
                sx={{
                  borderBottom:
                    tab == "Models" ? "1px solid black" : "0px solid black",
                  display: "flex",
                  gap: "5px",
                  padding: "10px",
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor:
                      tab == "Models" ? "white" : "rgba(0, 0, 0, 0.04)", // Hover background color
                  },
                }}
                onClick={() => setTab("Models")}
              >
                <StormIcon />
                <Typography>Models</Typography>
              </Box>
              <Box
                sx={{
                  borderBottom:
                    tab == "Streams" ? "1px solid black" : "0px solid black",
                  display: "flex",
                  gap: "5px",
                  padding: "10px",
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor:
                      tab == "Streams" ? "white" : "rgba(0, 0, 0, 0.04)", // Hover background color
                  },
                }}
                onClick={() => setTab("Streams")}
              >
                <ShowChartIcon />
                <Typography>Streams</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                sx={{
                  borderColor: "rgba(70, 67, 212, 1)",
                  color: "rgba(70, 67, 212, 1)",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "rgba(70, 67, 212, 0.8)", // Hover background color
                    color: "rgba(70, 67, 212, 1)",
                  },
                  width: "120px",
                  p: 0,
                  m: 0,
                }}
                startIcon={<AddIcon />}
                endIcon={<ArrowDropDownIcon />}
                variant="text"
              >
                New
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box padding={"8px 0px"}>
            {tab == "Models" &&
              ["Vibration Forecasting (1min)", "Vibration Anomaly"].map(
                (model) => (
                  <ListItemButton>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        gap: "10px",
                        padding: "4px, 0px",
                      }}
                    >
                      <StormIcon sx={{ fill: "rgba(70, 67, 212, 1)" }} />
                      <Typography>{model}</Typography>
                    </Box>
                  </ListItemButton>
                )
              )}
            {tab == "Streams" &&
              ["Average Pressure", "Quality Time Shift"].map((model) => (
                <ListItemButton>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "10px",
                      padding: "4px, 0px",
                    }}
                  >
                    <ShowChartIcon sx={{ fill: "rgba(70, 67, 212, 1)" }} />
                    <Typography>{model}</Typography>
                  </Box>
                </ListItemButton>
              ))}
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
