import { useEffect, useState } from 'react';

import { Box, IconButton } from "@mui/material";
import { Table, TableCell, TableHead, Typography, TableBody, TableRow } from "@mui/material";

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ThermostatIcon from "@mui/icons-material/Thermostat";
import SensorsIcon from "@mui/icons-material/Sensors"

import SparkLine from "./sparkline";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import ModelIcon from '../../assets/model-icon.png';
import EventsTSGraph from './events-graph';
import EventIcon from '@mui/icons-material/Event';

import dayjs from "dayjs";

import { GROUP_COLORS, COLORS } from '../../constants';

export default function EventsTable({startDate, endDate, eventsData, events, sensors}) {
    const [selectedEvent, setSelectedEvent] = useState(null);

    const HeaderCell = ({column, description}) => {
        return (
            <TableCell>
                <Typography
                    sx={{
                        fontWeight:'600',
                        fontSize:'11px'
                    }}
                >
                    {column}
                    {
                        description &&
                        <span style={{fontWeight:500}}> ({description})</span>
                    }
                </Typography>
            </TableCell>
        );
    }

    const EventsCell = ({event, idx}) => {
        return (
            <TableCell
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'8px',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: COLORS[idx + sensors.length],
                            borderRadius:'5px',
                            pr:'2px',
                            pl:'2px',
                            // height:'30px',
                            // width:'30px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center'
                        }}
                    >
                        <EventIcon sx={{fill:'#FFF'}} />
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'5px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize:'14px',
                                fontWeight:'550'
                            }}
                        >{event.name}</Typography>
                        <Typography
                            sx={{
                                fontSize:'11px',
                                fontWeight:'450'
                            }}
                        >{event.description}
                        </Typography>
                    </Box>
                </Box>
            </TableCell>
        )
    }

    const TotalEventsCell = ({event}) => {
        return (
            <TableCell
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'8px',
                    }}
                >
                    <Typography sx={{fontSize:'14px', fontWeight:'550'}}>
                        {eventsData[event.event_id].events_list.length}
                    </Typography>
                </Box>
            </TableCell>
        );
    }

    const TotalDurationCell = ({event}) => {
        return (
            <TableCell
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'row',
                        gap:'8px',
                        alignItems:'center'
                    }}
                >
                    <Typography sx={{fontSize:'14px', fontWeight:'550'}}>
                        {eventsData[event.event_id].events_list.reduce((sum, item) => sum + item.duration_min, 0)}
                    </Typography>
                    <Typography
                            sx={{
                                fontSize:'11px',
                                fontWeight:'450'
                            }}
                        >minutes
                    </Typography>
                </Box>
            </TableCell>
        );
    }

    const handleEventSelect = (event) => {
        if(selectedEvent && (event.name === selectedEvent.name)){
            setSelectedEvent(null);
        }else{
            setSelectedEvent(event);
        }
    }


    const EventRow = ({event, idx}) => {
        var selected = false;
        if(selectedEvent && selectedEvent.name === event.name){
            selected = true;
        }
        return (
            <TableRow 
                sx={{
                    backgroundColor: selected ? 'rgba(114, 110, 255, 0.08)' : 'white',
                    borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                }}
                onClick={() => handleEventSelect(event)}
            >
                <EventsCell event={event} idx={idx}/>
                <TotalEventsCell event={event}/>
                <TotalDurationCell event={event}/>
            </TableRow>
        );
    }

    const EventsGraph = ({event, idx}) => {
        console.log(event.events_list);
        return (
            <TableRow
                sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                }}
            >
                <TableCell
                    colSpan={5}
                >
                    <Box
                        sx={{
                            padding:'15px'
                        }}
                    >
                        <Box
                            sx={{
                                padding:'10px',
                                height:'500px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center'
                            }}
                        >
                            <EventsTSGraph color={COLORS[sensors.length + idx]} startDate={startDate} endDate={endDate} events={event.events_list}/>
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
        );
    }

    const [rows, setRows] = useState([]);
    useEffect(() => {
        if(!selectedEvent && eventsData){
            const _rows = events.map((event, idx) => (
                <EventRow event={event} idx={idx}/>
            ))
            setRows([..._rows]);
        }else if(selectedEvent && eventsData){
            var _rows = [];
            events.forEach((event, idx) => {
                _rows.push(<EventRow event={event} idx={idx}/>);
                if(event.event_id === selectedEvent.event_id){
                    _rows.push(<EventsGraph idx={idx} event={eventsData[selectedEvent.event_id]}/>)
                }
            })
            setRows([..._rows]);
        }

    }, [events, sensors, selectedEvent, eventsData])

    return (
        <Box
            sx={{
                borderTopLeftRadius:'10px',
                borderTopRightRadius:'10px',
                overflow:'hidden',
                border: '1px solid rgba(0, 0, 0, 0.15)'
            }}
        >
            <Table>
                <TableHead
                    sx={{
                        backgroundColor:'#F7F7F7',
                        fontWeight:'500',
                        fontSize:'11px',
                    }}
                >
                    <HeaderCell column={'Event'}/>
                    <HeaderCell column={'Total Events'} description={'Last 6 hours'}/>
                    <HeaderCell column={'Total Duration'} description={'Last 6 hours'}/>
                </TableHead>
                <TableBody>
                    {
                        rows.map((row) => (row))
                    }
                </TableBody>
            </Table>
        </Box>
    );
}